import {Button, Typography} from "@mui/material";
import {apiService} from "../../application/entities/api/apiService";
import {useEffect, useState} from "react";
import {ICompanyUsers} from "../../application/entities/dataTypes/companyUser";
import Box from "@mui/material/Box";
import {useOpenDialogWindow} from "../../components/live/DialogWindow";
import {IOpenDialogAction} from "../../redux/slices/dialog.slices";
import {useAuthActions} from "../../redux/slices/auth.slices";
import {useAppToast} from "../../components/live/AppToast";
import {objectApi} from "../../application/entities/dataApi";
import {useUserQuery} from "../../components/hooks/data/useUserQuery";

export const MfaDisableButton = (props: { userid: number }) => {

    const [user, setUser] = useState<ICompanyUsers | undefined>(undefined);
    const [mfaDisableAuthorized, setMfaDisableAuthorized] = useState<boolean>(false);
    const dialogWindow = useOpenDialogWindow();
    const {disableMfa} = useAuthActions();
    const appToast = useAppToast();

    const {refetch} = useUserQuery({userId: props.userid});

    const searchLoggedInUser = () => {
        return apiService
            .entity('companyUsers')
            .find('loggedInUser')
            .fetch();
    };
    const userApi = new objectApi.companyUsers();

    useEffect(() => {
        searchLoggedInUser().then(res => {
            setUser(res.data)
            if (res.data.id === props.userid) {
                setMfaDisableAuthorized(true)

            }
        })
    }, [props.userid])


    const handleDisableMfa = () => {
        const confirmDialogConf = {
            buttonActions: {
                acceptButton: "Disable MFA",
                cancelButton: "Cancel",
            },
            description: "Removing Multi-factor Authentication may cause security issues. Are you sure you want to disable it?",
        };
        const confirmDialog: IOpenDialogAction = {
            dialogId: "confirmDisableMFA",
            buttonActions: confirmDialogConf.buttonActions,
            title: "Are you sure you want to disable MFA?",
            description: confirmDialogConf.description,
            icon: "Info",
            onAccept: () => {
                disableMfa().then(() => {
                    userApi.update(user!.id!, {isMfaSetupDone: false}).then(res => {
                        appToast.success({
                            message: 'Multi-factor Authentication disabled',
                        });


                    }).finally(() => {
                        refetch()
                    });
                }).catch(e => {
                    appToast.error({
                        message: e?.message ?? 'An error occurred',
                    });
                })

            },
        };
        confirmDialog.onClose = () => {
            dialogWindow.close("confirmDisableMFA");
            refetch()

        };
        dialogWindow.open(confirmDialog);

    }

    if (!mfaDisableAuthorized || user?.mfaMandatory) {
        return <></>
    }

    return <Box
        sx={{ml: 3, p: 3, borderRadius: '20px', width: "652px", backgroundColor: '#fff', border: '1px solid #ececec'}}>
        <Typography variant="h4" sx={{fontSize: '18px', fontWeight: 'bold', color: "#7587a3"}} mb={2}>Multi-factor
            Authentication</Typography>
        <Typography variant="body1" sx={{fontSize: '14px', color: "#7587a3"}} mb={2}>
            Your account has Multi-factor Authentication enabled. You can disable it by clicking the button below.
        </Typography>
        <Button variant="contained" onClick={() => {
            handleDisableMfa()
        }}>Disable MFA</Button>
    </Box>
}
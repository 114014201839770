import {DspCheckoutParametersBox} from "./DspCheckoutParametersBox";
import React from "react";
import {useAudienceEnablementStore} from "../../store/AudienceEnablement.store";
import {useShallow} from "zustand/react/shallow";
import {
    getCPMFromSegmentsList,
    getCPMToDisplay,
    getTotalFromIdsCountList,
    getTotalIdsForSegmentsList
} from "../../../../SegmentBuilder/Segments.utils";
import {getDspAdditionalInfo, getDspIcon} from "../../../../../application/utils/dsp.utils";
import {Box, Button, Typography} from "@mui/material";
import {AppIcon} from "../../../../../components/ui/AppIcon";
import {profilesFormatter} from "../../../../../_configuration/formaters";
import {IExternalSegment} from "../../../../../application/entities/dataTypes/externalSegment";
import {useOpenDialogWindow} from "../../../../../components/live/DialogWindow";
import {DspTable, DspTableRow} from "../DspSelectionStep";
import {moneyFormatter} from "../../../../../application/utils/formatters.utils";

export const SummarySegmentChannels = () => {
    const selectionStepForm = useAudienceEnablementStore(useShallow((state) => state.selectionStepForm));
    const summary = selectionStepForm.filter((item) => item.segmentSelection?.length);

    const getSegmentCpm = useAudienceEnablementStore(useShallow((state) => state.actions.getSegmentCpm))
    const currency = useAudienceEnablementStore(useShallow((state) => state.currency))


    const dialogWindow = useOpenDialogWindow();


    const openChannelSegmentsDetails = (segments: IExternalSegment[]) => {
        dialogWindow.open({

            title: "Channel Segments Selection",
            dialogId: "ChannelSegmentsDetails",
            component: ChannelSegmentsDetails,
            componentProps: {
                segments: segments
            },
        });
    };

    return <>


        <Box px={2} mb={4} sx={{
            pt: 0,
            background: "#fafafa",
            borderRadius: "8px",
            border: "1px solid #ececec",

        }}>
            <DspTable>
                <DspTableRow gridTemplate={"1fr 1fr 150px 120px 120px"} header={true}>
                    <Box>
                        <Typography sx={{
                            textAlign: 'left',
                            fontSize: "12px",
                            fontWeight: "bold"
                        }}>Enablement Channel</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{
                            textAlign: 'center',
                            fontSize: "12px",
                            fontWeight: "bold"
                        }}>Enablement Option</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{
                            textAlign: 'center',
                            fontSize: "12px",
                            fontWeight: "bold"
                        }}>Number of Segments</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{
                            textAlign: 'center',
                            fontSize: "12px",
                            fontWeight: "bold"
                        }}>Max Ids Volume</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{
                            textAlign: 'center',
                            fontSize: "12px",
                            fontWeight: "bold"
                        }}>CPM</Typography>
                    </Box>
                </DspTableRow>
                {summary.map(dspItem => {
                    const additionnalInfo = getDspAdditionalInfo(dspItem.code);
                    const totalIds = getTotalIdsForSegmentsList(dspItem.externalSegments)
                    const maxIds = getTotalFromIdsCountList(totalIds)
                    const selectedSegments = dspItem.externalSegments?.filter(segment => {
                        return dspItem.segmentSelection?.includes(segment.uuid!)
                    })

                    const {cpmMin, cpmMax} = getCPMFromSegmentsList(selectedSegments)
                    const displayedCpm = (!cpmMin && !cpmMax) ? 'Not set' : getCPMToDisplay(getSegmentCpm(cpmMin), getSegmentCpm(cpmMax), currency)

                    return <DspTableRow key={dspItem.code} gridTemplate={"1fr 1fr 150px 120px 120px"}>
                        <Box sx={{display: "flex", alignItems: "center", gap: "0.5rem"}}>
                            <AppIcon fontSize="smallbis" icon={getDspIcon(dspItem.activationPlatformCode)}/>
                            <Typography sx={{fontWeight: "bold"}}
                                        data-cy={"activationPlatform"}>
                                {dspItem.activationPlatform}
                            </Typography>

                        </Box>

                        <Box>

                            <Box sx={{
                                display: 'flex',
                                gap: '0.5rem',
                                alignItems: 'center',
                                justifyContent: 'center',

                            }}>
                                <AppIcon fontSize={"small"} icon={getDspIcon(dspItem.code)}/>
                                <Typography data-cy={"seatName"}>
                                    {additionnalInfo?.name ?? dspItem.name}
                                </Typography>
                            </Box>
                            {dspItem.dspCheckoutParameterGroups.length ? (
                                <DspCheckoutParametersBox key={dspItem.code}
                                                          paramGroups={dspItem.dspCheckoutParameterGroups}
                                                          alignCollapseToggle={'center'}
                                                          backgroundColor={"#fcfcfc"}></DspCheckoutParametersBox>
                            ) : null
                            }
                        </Box>

                        <Box sx={{textAlign: "center"}}>
                            <Button variant="contained" size="small" sx={{my: "0.5rem", mx: "auto"}}
                                    onClick={() => openChannelSegmentsDetails(selectedSegments!)}>
                                {selectedSegments?.length} segments
                            </Button>
                        </Box>

                        <Box>
                            <Typography align="center" data-cy={"maxIds"} noWrap={true}>
                                {profilesFormatter.format(maxIds)}
                            </Typography>
                        </Box>

                        <Box sx={{textAlign: "center"}}>
                            {displayedCpm}
                        </Box>
                    </DspTableRow>
                })}
            </DspTable>
        </Box>
    </>
}

const ChannelSegmentsDetails = (props: { segments: IExternalSegment[] }) => {
    const getSegmentCpm = useAudienceEnablementStore(useShallow((state) => state.actions.getSegmentCpm))
    const currency = useAudienceEnablementStore(useShallow((state) => state.currency))
    return <Box sx={{minWidth: "500px", padding: "1rem"}}>

        <DspTable>
            <DspTableRow gridTemplate={"1fr 120px 120px"} header={true}>
                <Box>
                    <Typography sx={{
                        textAlign: 'left',
                        fontSize: "12px",
                        fontWeight: "bold"
                    }}>Segment Name</Typography>
                </Box>
                <Box>
                    <Typography sx={{
                        textAlign: 'center',
                        fontSize: "12px",
                        fontWeight: "bold"
                    }}>Max Ids Volume</Typography>
                </Box>
                <Box>
                    <Typography sx={{
                        textAlign: 'center',
                        fontSize: "12px",
                        fontWeight: "bold"
                    }}>CPM</Typography>
                </Box>
            </DspTableRow>
            {props.segments?.map(segment => {
                const totalIds = getTotalIdsForSegmentsList([segment])
                const maxIds = getTotalFromIdsCountList(totalIds)
                return <DspTableRow key={segment.uuid} gridTemplate={"1fr 120px 120px"}>
                    <Box>
                        <Typography sx={{
                            textAlign: 'left',
                            fontSize: "12px",

                        }}>{segment.segmentName}</Typography>
                        <Typography sx={{
                            textAlign: 'left',
                            fontSize: "12px",
                            color: "#7587A3"
                        }}>{segment.segmentDescription}</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{
                            textAlign: 'center',
                            fontSize: "12px",
                        }}>{profilesFormatter.format(maxIds)}</Typography>
                    </Box>
                    <Box>
                        <Typography sx={{
                            textAlign: 'center',
                            fontSize: "12px",
                        }}>{moneyFormatter(getSegmentCpm(segment.segmentCpm ?? 0), {currency: currency})}</Typography>
                    </Box>
                </DspTableRow>
            })}
        </DspTable>

    </Box>
}
import {Box, styled} from '@mui/material';
import React, {FunctionComponent} from 'react';
import {useUserQuery} from '../../components/hooks/data/useUserQuery';
import {ICompanyUsers} from '../../application/entities/dataTypes/companyUser';
import {CompanyNavigation} from '../Companies/shared/CompanyNavigation';
import {PageId} from '../../application/pages/pages.config';
import {MainFrame} from '../../components/templates/MainFrame';
import {FormBlock} from '../../components/templates/InfoBlock';
import {useParams} from 'react-router-dom';
import {IAccounts} from '../../application/entities/dataTypes/accounts';
import {UserProfileForm} from './UserProfileForm';
import {H4} from '../../components/ui/AppElements';
import {GlobalAvatar} from '../../components/ui/GlobalAvatar';
import {BrandNavigation} from '../Brands/shared/BrandNavigation';
import {MfaDisableButton} from "./MfaDisableButton";
import {MfaSetup} from "./MfaSetup";

interface IUserProfileUpdateProps {
    userId: number;
};


export const UserProfileUpdate: FunctionComponent<IUserProfileUpdateProps> = (props: IUserProfileUpdateProps) => {
    const uriParams = useParams<{ userId: string; }>() as { userId: string };
    const userId = props?.userId ?? +uriParams?.userId;

    const {data} = useUserQuery({userId: userId});
    const userData: ICompanyUsers = data ?? {};
    const accountType = userData?.account?.accountType;


    const headerContent = <div className={'flex-h flex-align-middle pad-tb'}>
        <Box sx={{px: 2}}>
            <GlobalAvatar
                user={userData}
                isPending={userData.status !== 'ACTIVATED'}
                size={'34px'}
                fontSize={'18px'}
            />
        </Box>
        <Box>
            <H4>{userData.firstName} {userData.lastName}</H4>
            <p>{`User ID#: ${userData.id}`}</p>
            <p>{userData?.account?.companyName}, {userData?.account?.country}`</p>
        </Box>
    </div>;

    const nav = !userData?.account?.accountType ? '' : userData?.account?.accountType === 'COMPANY' ?
        <CompanyNavigation account={userData.account as IAccounts}/> :
        <BrandNavigation brand={userData.account as IAccounts}/>;

    // CMVP-1984 if user of a company and administrator : do not show edit permissions
    const adminMessage = (accountType === 'COMPANY' && userData.role === 'ADMINISTRATOR') ? 'Company Administrators have permissions for all company brands' : undefined

    return <MainFrame
        headerProps={{
            hideHeaderDefault: true,
            hideHeaderIcon: true,
            headerButtons: nav,
        }}
        headerContent={headerContent}
        frameId={PageId.userProfileUpdate}>
        <UserProfileUpdateContainer>
            <div className="flex-main flex-h gap-small">
                <FormBlock className={'pad-ii-3 pad-b-2'} noBorder
                           fullHeight
                           style={{width: '700px'}}
                    //buttons={<Button onClick={() => {setInviteUser(!inviteUser);}} variant={'outlined'}>invite user</Button>}
                           title={'User Profile'}>
                    <UserProfileForm userData={userData}/>
                </FormBlock>
                <div>
                    {/*{(AUTHUser.accountType() === 'COMPANY'  ) && <FormBlock noBorder title={'Brands and Permissions'}
          description={adminMessage}>
            {!Boolean(adminMessage) && <ShadowBox className={'pad-2'}>
                <UserBrandAssociationTable userId={userId}/>
            </ShadowBox>}
          </FormBlock>}*/}
                </div>
            </div>
            {(userData?.isMfaSetupDone !== undefined && userData.isMfaSetupDone) &&
                <MfaDisableButton userid={userData.id}/>}
            {(userData?.isMfaSetupDone !== undefined && !userData.isMfaSetupDone) && <MfaSetup userid={userData.id}/>}


        </UserProfileUpdateContainer>
    </MainFrame>;


};

interface IUserProfileUpdateContainerProps {
};

const UserProfileUpdateContainer = styled(Box)<IUserProfileUpdateContainerProps>((props) => ({
    backgroundColor: props.theme.palette.background.default,
    maxHeight: '100%',
    height: '100%',
    overflowY: 'auto',
    width: '100%',
    paddingBottom: '1rem'
}));


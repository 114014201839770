import {useAudienceBuilderStore} from "../../Store/AudienceBuilderStore";
import React, {useEffect, useState} from "react";
import {ColumnsWrap} from "../../../../components/ui/AppElements";
import {AppLabel} from "../../../../components/ui/AppLabel";
import {Box} from "@mui/material";
import {IFeaturesDto} from "../../../../application/entities/dataTypes/features";

interface InputAgeChildrenProps {
    featureCode: string;
    selectedValues?: { min: number; max: number };
}

export const InputAgeChildren = (props: InputAgeChildrenProps) => {

    const feature = useAudienceBuilderStore.getState().getFeatureByCode(props.featureCode).feature;
    const audienceActions = useAudienceBuilderStore.getState().actions;
    const filtersValues = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
        return el.code === props.featureCode;
    })[0];
    const locationFilterValues = useAudienceBuilderStore((st) => st.filtersValues).filter((el: any) => {
        return el.code === "LOCATION";
    })[0];


    const countriesAllowed = ["United States", "Australia", "United Kingdom"];

    const allowedCountriesSelected = countriesAllowed.map(countryName => {
        return {
            country: countryName,
            selected: locationFilterValues?.selected.some(selected => selected.path.includes(countryName))
        }
    })


    const isFilterActivated = allowedCountriesSelected.filter(item => item.selected).length === 1


    const [rangesSelected, setRangesSelected] = useState<Array<{
        min: number,
        max: number
    }>>(filtersValues.selected ?? []);


    useEffect(() => {
        if (filtersValues.selected.length === 0) {
            setRangesSelected([])
        }
    }, [filtersValues.selected])


    if (!isFilterActivated) {
        return <Box sx={{width: "100%", position: "relative"}}>
            <Box data-cy="brand-interest-disabled" sx={{
                borderRadius: "7px",
                padding: "0.5rem",
                backgroundColor: "#f5f5f5"
            }}>
                <p style={{color: "#7587a3"}}>
                    To enable Age of Children filter, select only one of the following countries from the{" "}
                    <span
                        style={{cursor: "pointer", color: "#00b5e2"}}
                        onClick={() => audienceActions.setActiveFeatureGroupCode(locationFilterValues.groupCode)}
                    >
              location filter
            </span>
                    : <br/>
                    - United Kingdom
                    <br/>- Australia
                    <br/>- United States
                </p>
            </Box>
        </Box>
    }

    const countrySelected = allowedCountriesSelected.filter(item => item.selected)[0].country
    let ranges: Array<{ min: number, max: number }> | null = null;

    const locationBasedRange: Record<string, Array<{ min: number, max: number }>> = {
        'United States': [{min: 0, max: 6}, {min: 6, max: 10}, {min: 11, max: 15}, {min: 16, max: 17}],
        'Australia': [{min: 0, max: 10}, {min: 11, max: 17}],
        'United Kingdom': [{min: 0, max: 4}, {min: 5, max: 11}, {min: 12, max: 17}],
    }
    ranges = locationBasedRange[countrySelected]


    const handleClickRange = (min: number, max: number) => {
        const newRanges = [...rangesSelected];
        if (newRanges.find((range) => range.min === min && range.max === max)) {
            newRanges.splice(newRanges.findIndex((range) => range.min === min && range.max === max), 1);
        } else {
            newRanges.push({min, max});
        }
        setRangesSelected(newRanges);

        audienceActions.insertFeatureValues(feature as IFeaturesDto, newRanges);
    }
    return <>
        <ColumnsWrap>

            {ranges?.map((range, index) => {
                return (
                    <AppLabel
                        selected={rangesSelected.filter((selected) => selected.min === range.min && selected.max === range.max)?.length ? true : false}
                        dataCy={"label" + range.min + "-" + range.max}
                        // id={feature.feature!.id}
                        key={range.min}
                        noMaxWidth={true}
                        index={index}
                        onClick={(id, index) => {
                            handleClickRange(range.min, range.max)
                        }}
                        text={`${range.min} - ${range.max}`}
                    />
                )
            })}


        </ColumnsWrap>
    </>
}
import {Dashboard} from "../../pages/Dashboard";
import {EvorraDashboard} from "../../pages/EvorraDashboard";
import {PageId} from "./pages.config";

import {AccountsList} from "../../pages/Accounts/List";
import {EditAccount} from "../../pages/Accounts/Update";
import {ViewAccount} from "../../pages/Accounts/View";
import {AudiencesList} from "../../pages/Audiences/List";
import {Users} from "../../pages/CompanyUsers/List";
import {UserEdit} from "../../pages/CompanyUsers/Update";
import {UserView} from "../../pages/CompanyUsers/View";
import {DataVaultPermissions} from "../../pages/DataVault/DataVaultPermissions";
import {DataVaultSetup} from "../../pages/DataVault/DataVaultSetup";
import {DspAccountChannelParameters} from "../../pages/DspAccountChannelParameters";
import {PrePackagedLists} from "../../pages/PrePackagedLists";

import {AdminAccountView} from "pages/Accounts/admin/AdminAccountView";
import {NewAudienceBuilder} from "pages/AudienceBuilder";
import {AudienceBuilderSelection} from "pages/AudienceBuilder/AudienceBuilderSelection";
import {AdminAudiencesList} from "pages/Audiences/Admin/AdminAudiencesList";
import {AudienceSetManualStep} from "pages/Audiences/Admin/AudienceSetManualStep";
import {AudiencesAlwaysOn} from "pages/Audiences/Admin/AudiencesAlwaysOn";
import {AudiencesManualStepList} from "pages/Audiences/Admin/AudiencesManualStepList";
import {AudienceApproval} from "pages/Audiences/Approval";
import {AudienceEnablement} from "pages/Audiences/Enablement";
import {AudienceView} from "pages/Audiences/view";
import {CreateBrand} from "pages/Brands/CreateBrand";
import {UpdateBrand} from "pages/Brands/UpdateBrand/UpdateBrand";
import {BusinessCockpit} from "pages/BusinessCockpit";
import {ChannelsSetup} from "pages/ChannelsSetup";
import {ChannelsSetupAdd} from "pages/ChannelsSetup/ChannelsSetupAdd/ChannelsSetupAdd";
import {ChannelsSetupEdit} from "pages/ChannelsSetup/ChannelsSetupEdit/ChannelsSetupEdit";
import {CreateCompany} from "pages/Companies/CreateCompany";
import {ListCompany} from "pages/Companies/ListCompany";
import {UpdateCompany} from "pages/Companies/UpdateCompany";
import {Lists} from "pages/Lists";
import {MyDatas} from "pages/MyDatas";
import {UploadFirstParty} from "pages/MyDatas/upload/uploadFirstParty";
import {AccountCreate} from "../../pages/Accounts/Create";
import {UserProfileUpdate} from "../../pages/Agencies/UserProfileUpdate";
import {UserProfileView} from "../../pages/Agencies/UserProfileView";
import {DashBoardBrand} from "../../pages/Brands/DashBoardBrand";
import {ListBrandUsers} from "../../pages/Brands/ListBrandUsers";
import {ViewBrand} from "../../pages/Brands/ViewBrand";
import {DashBoardCompany} from "../../pages/Companies/DashBoardCompany";
import {ListCompanyUsers} from "../../pages/Companies/ListCompanyUsers";
import {ViewCompany} from "../../pages/Companies/ViewCompany";
import {CreateUser} from "../../pages/CompanyUsers/Create";
import {GlobalCockpit} from "../../pages/GlobalCockpit";
import {Reports} from "../../pages/GlobalCockpit/Reports";
import SuperAdminBrandsSettings from "../../pages/AccountSuperAdmin/SuperAdminBrandsSettings";
import {BrandUserManagement} from "../../pages/Brands/userManagement/brandUserManagement";
import {SegmentBuilder} from "../../pages/SegmentBuilder";
import {MfaCode} from "../../components/features/Authentication/MfaCode";
import {MfaSetup} from "../../components/features/Authentication/MfaSetup";

/**
 * used by Router
 * removed from pages.config.ts, causing memory leaks and ref loops
 * */
export const pageComponents: Partial<Record<PageId, any>> = {
    [PageId.dashboardDataProvider]: Dashboard,
    [PageId.EvorraDashboard]: EvorraDashboard,
    [PageId.accountCreate]: AccountCreate,
    [PageId.reports]: Reports,
    [PageId.accountList]: AccountsList,
    [PageId.accountView]: ViewAccount,
    [PageId.accountEdit]: EditAccount,
    [PageId.users]: Users,
    [PageId.userEdit]: UserEdit,
    [PageId.userView]: UserView,
    [PageId.userCreate]: CreateUser,
    [PageId.vaultPermissions]: DataVaultPermissions,
    [PageId.dataVault]: DataVaultSetup,
    [PageId.vaultSetup]: DataVaultSetup,
    [PageId.DspAccountChannelParameters]: DspAccountChannelParameters,
    [PageId.myList]: PrePackagedLists,
    [PageId.lists]: Lists,
    [PageId.adminAccountView]: AdminAccountView,
    [PageId.audienceSetManualStep]: AudienceSetManualStep,
    [PageId.AudiencesManualStepList]: AudiencesManualStepList,
    [PageId.audiencesAlwaysOn]: AudiencesAlwaysOn,
    [PageId.myDatas]: MyDatas,
    [PageId.uploadFirstParty]: UploadFirstParty,

    // audiences
    [PageId.audienceBuilderSelection]: AudienceBuilderSelection,
    [PageId.audienceBuilderForm]: NewAudienceBuilder,
    [PageId.audienceBuilderFormTravel]: NewAudienceBuilder,
    [PageId.audienceBuilderFormInterest]: NewAudienceBuilder,
    [PageId.audienceLibrary]: AudiencesList,
    [PageId.audienceApproval]: AudienceApproval,
    [PageId.audienceView]: AudienceView,
    [PageId.newAudienceBuilder]: NewAudienceBuilder,
    [PageId.audienceAdminList]: AdminAudiencesList,
    [PageId.channelsSetup]: ChannelsSetup,
    [PageId.channelsSetupAdd]: ChannelsSetupAdd,
    [PageId.channelsSetupEdit]: ChannelsSetupEdit,
    [PageId.audienceEnablement]: AudienceEnablement,

    // cockpit
    [PageId.globalCockpit]: GlobalCockpit,
    [PageId.businessCockpit]: BusinessCockpit,
    // user profiles
    [PageId.userProfileView]: UserProfileView,
    [PageId.userProfileUpdate]: UserProfileUpdate,
    // brand
    [PageId.brandAgentList]: ListBrandUsers,
    [PageId.brandCreate]: CreateBrand,
    [PageId.brandUpdate]: UpdateBrand,
    [PageId.brandView]: ViewBrand,
    [PageId.brandDashboard]: DashBoardBrand,
    [PageId.brandAudienceLibrary]: AudiencesList,
    [PageId.brandUserManagement]: BrandUserManagement,
    // company
    [PageId.companyCreate]: CreateCompany,
    [PageId.companyView]: ViewCompany,
    [PageId.companyDashboard]: DashBoardCompany,
    [PageId.companyUpdate]: UpdateCompany,
    [PageId.companyList]: ListCompany,
    [PageId.companyUserList]: ListCompanyUsers,
    [PageId.brandsSettingsSuperAdmin]: SuperAdminBrandsSettings,
    //
    [PageId.signin]: "signin",
    [PageId.verify_signup]: "verify_signup",
    [PageId.forgotten_code]: "forgotten_code",
    [PageId.retrieve_password]: "retrieve_password",
    [PageId.user_invitation]: "user_invitation",
    [PageId.user_activation]: "user_activation",
    [PageId.user_invitation_full]: "user_invitation_full",
    [PageId.mfa_code]: MfaCode,
    [PageId.mfa_setup]: MfaSetup,
    [PageId.adminBrandList]: "brand-admin-list",

    [PageId.segmentBuilder]: SegmentBuilder,
};

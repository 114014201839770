import {GridValueGetterParams} from '@mui/x-data-grid';
import {TDataColumnModel} from '../../utils/dataColumnModel.utils';
import {IUserAccountAssociation} from '../dataTypes/brandRoles';
import {accountTypeName, EUserGrade} from 'application/utils/granter.utils';
import {ICompanyUsers} from "../dataTypes/companyUser";


function getFullName(params: any) {
    return `${params.getValue(params.id, 'firstName') || ''} ${
        params.getValue(params.id, 'lastName') || ''
    }`;
}

export const companyBrandRolesColumns: TDataColumnModel = {
    firstName: {
        field: 'name',
        headerName: 'Name',
        sortable: false,
        minWidth: 120,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        type: 'avatar',
        valueGetter: (params: GridValueGetterParams<IUserAccountAssociation>) =>
            `${params.row.user.firstName || 'nada'} ${
                params.row.user.lastName || 'nada '
            }`,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
            getFullName(cellParams1)
                .localeCompare(getFullName(cellParams2)),
    },
    /*email    : {
      field     : 'email',
      headerName: 'Email',
      minWidth  : 90,
      flex      : 1,
      type      : 'string',
      valueGetter   : (params: GridValueGetterParams<IUserAccountAssociation>) =>{
        return  params.row.user?.email
      }
    },*/
    accountType: {
        field: 'accountType',
        headerName: 'Account type',
        minWidth: 90,
        width: 90,
        maxWidth: 90,
        flex: 1,
        type: 'string',
        valueGetter: (params: GridValueGetterParams<IUserAccountAssociation>) => {
            return accountTypeName[params.row.user.role as EUserGrade]

        }
    },
    role: {
        field: 'role',
        headerName: 'Role',
        minWidth: 80,
        width: 110,
        maxWidth: 110,
        flex: 1,
        type: 'string',
        valueGetter: (params: GridValueGetterParams<IUserAccountAssociation>) => {
            let role = params.row.role.replace('CONTRIBUTOR', 'VIEWER')
            return `${role.charAt(0).toUpperCase() + role.slice(1).toLowerCase() || ''}`
        }
        ,
    },
    mfa: {
        field: 'isMfaSetupDone',
        headerName: 'MFA',
        width: 70,
        maxWidth: 70,
        type: 'string',
        valueGetter: (params: GridValueGetterParams<ICompanyUsers>) => {
            return params.row.isMfaSetupDone ? 'Active' : 'No Set'
        }
        ,
    }
};
import {useAuthActions, useAuthState} from "../../../../redux/slices/auth.slices";
import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {ReactHookFormItem} from "../../../../application/entities/dataTypes/forms";
import {useForm} from "react-hook-form";
import Box from "@mui/material/Box";
import {AuthHeader, AuthSignZone, HtmlForm, InputWrapper} from "../authentication.styled";
import {InputLabel} from "../../../form/Input";
import {Button, CircularProgress, Collapse, Fade, TextField, Typography} from "@mui/material";
import {H5, HeaderZoneIconBar, HeaderZoneMessage, HeaderZoneTitle} from "../../../ui/AppElements";
import {AppIcon} from "../../../ui/AppIcon";
import {getLogoLogin} from "../../../../application/utils/domain.utils";
import {objectApi} from "../../../../application/entities/dataApi";
import {useNavigate} from "react-router";
import {authStore} from "../../../../stores/auth.store";

export const MfaSetup = () => {
    const {initializeMfaSetup, verifyMfaSetup, doSignOut} = useAuthActions();
    const {
        mfaSetupInProgress,
        mfaSetupSecret,
        mfaQRCode,
        showError,
        error,
        mfaEnabled,
        cognitoUser,
        user
    } = useAuthState();

    const {t} = useTranslation();
    const AUTHStore = authStore();
    const [loadingState, setLoadingState] = React.useState<boolean>(false);
    const [showSecretCode, setShowSecretCode] = React.useState<boolean>(false);

    const logoLogin = getLogoLogin()
    const navigate = useNavigate();
    useEffect(() => {
        initializeMfaSetup()
    }, [])
    const totpField: ReactHookFormItem = {
        name: 'totp',
        label: t('auth.mfa.totpLabel'),
        type: 'text',
        options: {
            minLength: {
                value: 6,
                message: t('auth.mfa.totpError')
            }
        }
    }


    const {
        handleSubmit,
        control,
        register,
        getValues,
        formState: {isValid, errors},
    } = useForm({mode: 'all'});
    const userApi = new objectApi.companyUsers();
    const onSubmit = (values: any) => {
        const code = values.totp as string
        verifyMfaSetup({code}).then(res => {
            userApi.update(user!.id!, {isMfaSetupDone: true}).then(res => {
                navigate('/')
            })
        });
    }

    const onBack = () => {
        AUTHStore.loggedIn = false

        doSignOut().then(() => {
            navigate("/");

        });

    }


    return <>
        <Fade in={true} timeout={1000} unmountOnExit>
            <AuthSignZone>
                <AuthHeader>
                    <HeaderZoneIconBar>
                        {(logoLogin) ?
                            <img src={logoLogin} alt="logo"
                                 style={{display: 'block', margin: 'auto', marginBottom: '1rem', maxHeight: '55px'}}/>
                            :
                            <AppIcon display="inline" icon="LoginIcon" fontSize="medium"/>
                        }
                    </HeaderZoneIconBar>
                    <HeaderZoneTitle>
                        <H5 display={'inline'}>Multi-factor Authentication Setup</H5>
                    </HeaderZoneTitle>
                    <HeaderZoneMessage>
                        <p>You need to setup your multi-factor authentication to sign in.</p>
                    </HeaderZoneMessage>
                </AuthHeader>
                <HtmlForm>
                    {mfaSetupInProgress && <p>Loading...</p>}
                    {mfaQRCode && (
                        <Box mb={2}>
                            <img src={mfaQRCode} alt="QR Code MFA"/>
                            <div>
                                <p>Scan the QR code with your Authenticator App</p>
                            </div>
                            <Button onClick={() => setShowSecretCode(!showSecretCode)}>Can't use QR Code?</Button>
                            <Collapse in={showSecretCode}>
                                <Box sx={{border: '1px solid #ececec', borderRadius: '7px', padding: '0.5rem'}}>
                                    <Typography variant="body1" sx={{fontSize: '12px', color: "#7587a3"}} mb={1}>
                                        You can use this secret code to setup your Multi-Factor Authentication App
                                    </Typography>
                                    {mfaSetupSecret && <Typography variant="body1" sx={{
                                        fontSize: '12px',
                                        color: "#7587a3",
                                        wordBreak: 'break-word',
                                        whiteSpace: 'normal',
                                    }}>{mfaSetupSecret}</Typography>}

                                </Box>
                            </Collapse>

                        </Box>
                    )}
                    {mfaSetupSecret && (
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <InputWrapper key={'totp'}>
                                <InputLabel>Enter the 6 digits code from your authenticator app</InputLabel>
                                <TextField
                                    {...register(totpField.name, totpField.options)}
                                    type={totpField.type}
                                    name={totpField.name}
                                    error={errors[totpField.name]}
                                    helperText={errors?.totpField?.message}
                                    style={{width: 224}}
                                />
                            </InputWrapper>


                            <br/>
                            <Button
                                id={'cyLoginButton'}
                                data-cy="login-button"
                                variant="contained"
                                type={'submit'}
                                startIcon={loadingState ?
                                    <CircularProgress style={{color: 'white'}} size={24}/> : undefined}
                                disabled={loadingState}
                            >
                                {t('auth.mfa.submitText')}
                            </Button>

                            <br/><br/>
                            <Button onClick={() => onBack()}>
                                Back to sign in
                            </Button>


                        </form>)}
                </HtmlForm>
            </AuthSignZone>
        </Fade>
    </>
}
export const translationEN = {
    brand: {
        archiveBrand: "Delete Brand?",
        archiveBrandDescription: "This brand will be deleted",
    },
    account: {
        userManagement: "User Management",
        accountDetailsLabels: {
            accountDetails: "Account Details",
            accountsIamApartOf: "Accounts I am a part of",
            contractFiles: "Contract Files",
            subscriptionDetails: "Subscription Details",
            companyName: "Company Name",
            companySize: "Company Size",
            industry: "Industry",
            companyUrl: "Company URL",
            accountType: "Account Type",
            parentCompany: "Parent Company",
        },
        accountAddressLabels: {
            companyStreetAddress: "Company Street Address",
            postalCode: "Post Code",
            state: "State",
            country: "Country",
            city: "City",
        },
        accountClientType: {
            clientType: "Client Type",
            clientTypesDescription: "Client Type Description",
            dataProvider: "Data Provider",
            dataClient: "Data Client",
        },
        accountConfParameters: {
            account_is_monthly_subscription: "Monthly subscription account",
            account_price_multiplier: "CPM multiplier",
            brand_monthly_subscription_usd: "Brand monthly subscription",
            company_monthly_subscription_usd: "Company monthly subscription",
            enable_impression_based_buy: "Allow Impression Based Buy",
            enable_pay_later: "Allow payment by invoice",
            fixed_provider_list: "Fixed Provider List",
            max_enablement_profiles: "Max Enablement Profiles",
            fixed_additional_cpm: "Fixed Additional CPM",
            agency_additional_cpm: "Agency Additional CPM",
            agency_cpm_multiplier: "Agency CPM Multiplier",
            enable_first_party_data: "Enable First Party Data",
            provider_share_on_gross_revenue: "Provider Share on Gross Revenue",
            provider_share: "Provider Share",
            provider_data_premium: "Provider Data Premium",
        },
        accountUsersLabels: {
            administrators: "Administrators",
            usersOnTheAccount: "Users on the Account",
        },
        accountContractLabels: {
            contractFiles: "Contract files",
        },
        accountPending: {
            title: "Pending",
            message: "This user is not activated yet",
        },
        accountSubscriptionLabels: {
            subscriptionDetails: "Subscription Details",
            startDate: "Start Date",
            renewalDate: "Renewal Date",
            subscriptionPlan: "Subscription Plan",
            noSubscription: "There is no subscription",
        },
        accountNotesLabels: {
            notes: "Notes",
            notesPlaceholder: "Enter any relevant notes or conversation from your client interaction...",
            saveNote: "Save Note",
            noteLength: "Note should not be shorter than 3 symbols or longer than 255 symbols",
        },
        userDetails: {
            userDetails: "User Details",
            title: "Title",
            titles: ["Mr.", "Mrs."],
            firstName: "First Name",
            surname: "Surname",
            name: "Name",
            email: "Email Address",
            jobTitle: "Job Title",
            phone: "Phone",
            companyName: "Company Name",
        },
        userRoleType: {
            roleType: "Role Type",
            roles: "Roles",
            role: "Role",
            administrator: "Administrator",
            creator: "Creator",
            contributor: "Contributor",
        },
        suspend: "Suspend",
        activate: "Activate",
        enable: "Enable",
        duplicate: "Duplicate",
        add: "Add New Account",
        edit: "Edit",
        archive: "Archive",
        delete: "Delete",
        review: "Review",
        payAudience: "Finalise Enablement",
        generalTitles: {
            info: "Info",
            users: "Users",
            userList: "Users list",
            status: "Status",
            invoicing: "Invoicing",
            selectUsers: "Select User",
            totalNumber: "Total Number",
            newUser: "Add New User",
            selected: "Selected",
            selectUser: "Select user",
            activeUsers: "Active users",
            brandList: "Brands List",
        },
        textAndWarnings: {
            warnDetailSpecificTotype:
                "Company accounts details are specific to being a Provider or a Client. If a company is both, they will have separate accounts.",
            allowInvoicesPayment: "allow payment by invoices",
            emailSendTo: "Email was sent to",
            resendEmail: "Resend Activation Email",
            warningNoAdmin: "There are no Administrators",
            warningNoUser: "There are no users",
            chooseMinOne: "You should choose minimum one",
            creationSuccessful: "Account created successfully",
        },
    },
    audience: {
        archiveAudience: "Archive this audience ?",
        contactAdminMoreMembers: "Contact your admin to invite more team members to Evorra",
        archiveAudienceDescription: "This audience will be archived",
        buildFirstAudience: "Build your first audience.",
        buildAudience: "Build an audience.",
        deleteAudience: "Delete this audience ?",
        channelRecommendation: "Channel recommendations",
        contactAdminChannelEnable: "Contact your admin to find out more about your Evorra enablement channels",
        connectChannels: "Connect channels",
        deleteAudienceDescription: "This audience will be deleted",
        duplicateAudience: "Duplicate this audience?",
        duplicateAudienceDescription: "This audience will be duplicated. Note: No Exclusion Lists associated to the audience will be copied over.",
        activateYourAudience: "Enable your audience",
        statusActivated: "Enabled",
        statusArchived: "Archived",
        statusDraft: "Draft",
        statusAll: "All",
        pendingApproval: "Pending Approval",
        approved: "Approved",
        seeAllAudiences: "Show all audiences",
        untitledAudience: "Untitled Audience",
        getStarted: "Get started",
        audienceSummary: "Audience Summary",
        easyBuilding: "It is easy to start building and enabling audiences on Evorra. Go on, jump in and start building.",
        minimum: "Minimum",
        moreAudiences: "More audiences",
        fewerAudiences: "Fewer audiences",
        messageAudienceSizeDelta: "There could be slightly fewer audiences enabled on the platform(s) than the ones you specify below",
        errorDeletingAudience: "An error occurred while deleting audience",
        acceptRequestApproval: "Request approved with success",
        denyRequestApproval: "Request declined with success",
        confirmApprovalError: "An error occurred while approving request",
        cancelRequest: "Cancel request",
        cancelRequestDescription: "This approval request will be canceled, the audience will be returned to draft status",
        cancelRequestConfirmation: "This request has been canceled and returned to draft status",
        cardTips: {
            transparencyTipTitle: "Transparency card",
            enablementTipTitle: "Enablement Card",
            channelTipTitle: "Estimated Audiences by Channel",
            transparencyTip:
                "This Transparency Card provides both a summary of the audience you are building (ie. name, CPM) and additional information about the data in that audience (ie. recency of the data or enablement metrics).\r\nAs you are building this audience, the information presented in the Transparency Card will update dynamically.",
            enablementTip:
                "The Enablement Card is always associated with every audience you build on Evorra.  Its purpose is to record any audience enablement transactions you make for a given audience.  For example, you can check here to know when you liscensed an audience, the related channel, enablement option, time, the associated audience expiration, and other useful enablement logs.",
            channelTip:
                "The data below provides a quick estimate of how many audiences you will be able to activate to the DSPs you have added to your Evorra account. Audiences numbers are within a 5% error margin. If there are no channels, please ensure you’ve added at least one channel to your Evorra account",
        },
        builder: {
            options: {
                recency: {
                    7: "Within the past 7 days",
                    14: "Within the past 14 days",
                    30: "Within the past 30 days",
                    60: "Within the past 60 days",
                    90: "Within the past 90 days",
                    100: "Over 90 days to 1 year",
                },
            },
        },
        activation: {
            createdBy: "Created by",
            profilesDeterministicIds: "Profiles with deterministic IDs",
            maxAudienceSize: "Unique Profiles",
            lastModified: "Last modified",
            enableAudience: "Enable your audience : ",
            payAndEnable: "Pay & Enable",
            payAndEnableIBB: "Enable",
            evorraAudienceId: "Evorra Audience ID",
            backToAudienceLib: "Back to audience library",
            confirmTermsConditions:
                "By ticking this box, I confirm that I understand and adhere to Evorra’s Platform Terms & Conditions and any additional terms in the Data Client Agreement and Statement of Work between my company and Evorra. I understand that once I hit “Pay and Activate”, the amount indicated above will be charged to my account, and that I am liable for the amount due, plus any applicable VAT.",
            confirmTermsConditionsIBB:
                "By ticking this box, I confirm that I understand and adhere to Evorra’s Platform Terms & Conditions and any additional terms in the Data Client Agreement and Statement of Work between my company and Evorra.",
        },
    },
    dataVault: {
        permissions: "Permissions",
        dataVault: "Data Vault",
        viewBillings: "View Billing & Payments",
        ruleDeleted: "rule was deleted",
        fetchedFine: "fetched fine, loaded",
        premiumUpdated: "Premium updated successfully",
        creationRequest: "Request to create Vault is sent",
    },
    uploadLists: {
        fileName: "Name of the list",
        description: "Optional Description",
        acceptTerms: "I confirm that the data being shared with Evorra complies with <0>Evorra’s Privacy & Data Policies</0>.",
        acceptTermsLink: "Evorra’s Privacy & Data Policies",
        acceptTermsNotice:
            "Important note: Evorra acts as a “processor” of personal data as defined by the\n" +
            "General Data Protection Regulator (GDPR). Please ensure that your contact information, along with the contact information for your data protection officer and/or EU " +
            "representative (if applicable) are up-to-date in <0>Account Settings Page</0>. Any notices under Evorra’s Privacy & Data Policies will be posted online and/or sent to you " +
            "directly.",
        file: "File",
        selectCsvFile: "Select CSV file",
        uploadExclusionList: "Upload Exclusion List",
        uploadDomainList: "Upload Domain List",
        downloadTemplate: "Download Excel Template",
        uploadExclusionListDescription: "Upload lists of your customers to exclude from audiences you create on Evorra",
        uploadExclusionListDescription2:
            "Please upload CSV files only. Files containing plain text will be hashed by Evorra. If you are uploading a hashed file, be sure to only hash email addresses, phone numbers, and names. Read our complete guide on uploading files successfully.",
        uploadDomainListDescription: "Upload a list of domains or URL’s to be used as a whitelist or a blacklist when you are enabling an audience.",
        uploadDomainListDescription2: "",
    },

    activation: {
        titleStep1: "Select the desired spend or size of each audience by channel",
        titleStep2: "Payment",
        titleStepApproval: "Request Approval",
        titleStep3: "Order Summary",
        titleStep4: "Confirmation",
        titleStep41: "Success! Your audience is being enabled!",
        congratulations: "Congratulations!\r\nYour audiences will appear in the following Enablement Channels shortly",
        willReceive: "You will receive an email confirmation shortly.",
        reminder: 'As a reminder, you can find this audience in the "enabled" section of the Audience Library.',
        buildNewAudience: "Build another audience",
        buildNewAudienceOnChannel: "Activate audience on another channel",
        infoStep1:
            "Difference in audiences numbers could be for two reasons:\r\n" +
            "\n" +
            "i. We conduct a real-time scan of the data at enablement. Any difference in actual audience size is typically less than 0.05%\n" +
            "\r\n" +
            "ii. If you have added an Exclusion List to this audience, matched IDs will be omitted as the scan is taking place. The percentage overlap between the Evorra and your Exclusion List audiences will define how much smaller the enabled audience will be on each platform you choose. \n" +
            "\r\n\r\n" +
            "Rest assured, you will only be charged for the actual audience numbers sent by Evorra to your chosen platforms.",
    },
    auth: {
        signIn: {
            title: "Login to your Account",
            message: "Sign in to your account below.",
            submitText: "Login",
            forgottenPasswordLink: "Forgotten password?",
            backToSignin: "Back to sign-in",
            passWordSet: "Password is set successfully",
            emailLabel: "Your email",
            emailError: "Please enter a valid email address",
            passwordLabel: "Password",
            passwordError: "Your password must be at least 8 characters long",
        },
        signOut: {
            title: "signout.title",
            message: "signout.message",
            submitText: "Sign out",
        },
        mfa: {
            title: "Multi-factor authentication",
            message: "Multi-factor authentication is required to sign in. Please enter the code from your authenticator app.",
            totpLabel: "TOTP Code",
            totpError: "This code is invalid",
            submitText: "Verify"
        },

        retrievePassword: {
            title: {
                step1: "Reset your password.",
                step2: "Reset your password.",
                step3: "Reset completed!",
            },
            message: {
                step1: `Don’t worry, it happens to the best of us.\r\n
                Enter your email below, and check your inbox for an email with a link from us to reset.`,
                step2: `Enter the reset password code sent to your inbox below, then, enter
                your new password to access your account.`,
                step3: `All done! You have successfully reset your password.`,
            },
            submitText: {
                step1: "Send code",
                step2: "Submit",
                step3: "Back to login",
            },
        },
        userInvitation: {
            title: {
                step1: "Activate your Account",
                step2: "Login",
            },
            message: {
                step1: "You've been invited to join Evorra. So you can jump right in, quickly setup your account below and then off you go!",
                step2: "You're nearly there. To finalize the enablement of your Evorra Account, just enter a new password",
            },
            submitText: {
                step1: "Log into Account",
                step2: "Confirm & Enter",
            },
        },
        noPasswordMatch: "Passwords do not match, please verify your input",
        termAndConditionApproval: `By ticking this box I accept that I have read the Terms of Service and agree to them.`,
    },
    labels: {
        add: "Add",
        addTag: "Add tag",
        createNew: "Create new",
        creating: "Creating",
        delete: "Delete",
        downloadAll: "Download all",
        download: "Download",
        error: "error",
        success: "success",
        somethingWrong: "Something went wrong",
        settings: "settings",
        setup: "Setup",
        modify: "Modify",
        quickTips: "Quick tip",
        nextTip: "next tip",
        back: "Back",
    },
    message: {
        account: {
            addingSuccess: "User added successfully",
            updatedSuccess: "The Account was updated successfully",
            deletedSuccess: "The Account was deleted successfully",
            active: "Active",
            inactive: "Inactive",
        },
        user: {
            updatedSuccess: "The User has been updated successfully.",
            deletedSuccess: "User deleted successfully",
            deleteUserValidation: "Are you sure you want to remove this user ?",
        },
    },
    globalCockpit: {
        globalReports: "Reports",
        reports: {
            accountReport: "Accounts and Users CSV",
            audienceReport: "Audiences and Features CSV",
            paymentReport: "Payment records CSV",
            idsConsentReport: "Ids and Consents CSV",
        },
        reportsLinks: {
            intentInterest: "Interests, Intents, & Demographics",
            globalCockpit: "Data Cockpit",
            businessCockpit: "Business Cockpit",
        },
    },
    dashboard: {
        demoAdminOnly: "Demo mode for admin dashboard",
        contactToPauseMonetize: "If you need to pause the monetization of your data, contact us.",
        dataNotMonetize: "Your data is currently not being monetized. To monetize, click on “Monetize My Data",
        monetizationEventCsv:
            "Tracked monetization events available in a CSV file format. Attention: the preliminary revenue amounts in this report are subject to a revenue verification process, which will confirm a) successfully completed monetisation events and b) the final revenue amount due to the data provider. Final due monetisation will be validated upon this verification process and in accordance with the data provider agreement.",
        dataProviderImplGuide: "Evorra Data Provider Simple Implementation Guide",
        million: "million",
        availableProfiles: "Available Unique Profiles",
        titles: {
            createDataPerm: "Create Data Permissions",
            controlData: "Control your data in the Evorra marketplace.",
            manageWhoUseData: "See and manage who can use your data.",
            monetizationStatus: "Data Monetization Status",
            reports: "Reports",
            monetizationLogs: "Monetization Logs",
            dataCollectionReport: "Data Collection Report",
            implementationResources: "Implementation Resources",
            dataCollectionApiInfo: "Evorra Data Collection API info",
            yourTeam: "Your team",
            inviteMore: "Invite more",
            yourDataList: "Your Data list",
            uploadList: "Upload",
            yourFeedback: "Your Feedback",
            writeFeedback: "Write",
            profiles: "Unique Profiles",
            weekOnWeekChange: "Week on week change",
            monthOnMonthChange: "Month on month change",
            profileMap: "Profile map",
        },
        status: {
            active: "Active",
            inactive: "Inactive",
            notAvailable: "not available",
            noChanges: "no changes",
        },
        actions: {
            increase: "Increase",
            decrease: "decrease",
        },
    },
    vaultSettingsPageLabels: {
        dataVaultSetup: "Data Vault Setup",
        yourVault: "Your Vault",
        yourIdentity: "Your Identity",
        awsIamUserName: "IAM user name",
        resetPassword: "Reset your password",
        resetApiKeys: "Reset your API keys",
        newPassword: "New Password",
        confirmPassword: "Confirm password",
        apiAccessKey: "API Access Key",
        apiSecretKey: "API Secret Key",
        createUpdateVault: "Create/update your vault",
        yourBucket: "Your bucket",
        yourTag: "Your tag",
        triggerDataPipeline: "Trigger your data pipeline",
        triggerForceDataPipeline: "Force data reprocessing",
        pipelineJobError: "Get Data Pipeline Workflow Job error type: ",
        jobRunState: "Job Run State: ",
        requestForceDataProcess: "Request Force data processing is sent",
        requestStartPipelineOk: "Request Start Data Pipeline Workflow is sent",
        lastVaultOperationStatus: "Vault Creation Status:",
        lastDataProcessingStatus: "Glue Job Status:",
        updateDataPremium: "Update data premium",
        dataPremium: "Data premium",
        enableTracker: "Enable tracker",
        enableTrackerDescription: "Javascript / pixel tracker ",
        enableUpload: "Enable upload",
        enableUploadDescription: "File delivery to S3 bucket",
    },
    builder: {},
    kpiClient: {
        totProfiles: {
            name: "Total Unique Profiles",
            desc: "This number indicates the total number of addressable, consented and unique profiles which exist on the Evorra platform, globally.",
        },
        wkChanges: {
            name: "Week on week change",
            desc: "Data Partners join the platform continually which can cause changes in the data available. Data Partners may temporarily suspend their data due to a technical issue or internal decisions. This number indicates the percentage change of the total data available on the Evorra platform, from the previous week.",
        },
        monthChanges: {
            name: "Month on month change",
            desc: "Data Partners join the platform continually which can cause changes in the data available. At times, these Data Providers may suspend their data due to a technical issue or internal decisions. This number indicates the percentage change of the total data available on the Evorra platform, from the previous month.",
        },
        totAvailableProfiles: {
            name: "Your available unique profiles",
            desc: "Not all the data on Evorra is available to all users. This is because Data Partners can add rules as to who can and cannot use their data due to competitive, legal or governance realities in their business. This number indicates the percentage of the 'Total Profiles' you have specific access to when building your audiences. This percentage will change and represents the percentage of data available to you today.",
        },
    },
    metrics: {
        status: {
            noData: "No data is available yet",
        },
    },
    list: {
        uploadSuccess: "The list is uploaded successfully",
        nameAlreadyExist: "The list with this name already exists",
        selectFile: "Select file from your computer",
        enterOwnFileName: "Enter your own file name",
        enterAdditionalDescription: "Enter an optional description",
    },
    userManagement: {
        msgSelectBrand: "Select a brand from the left panel to display the associated users who have been given brand permissions.",
        msgSelectUserTypeForBrand:
            "For the moment, no users have been given brand permissions for your selected brand.  You may use the top toggle control to display administrators who also have rights to manage the selected brand.  Consider adding new users to your account or assigning new brand permissions to existing users.",
    },
    credential: {
        invalidId: "The ID number could not be validated. Please check the number and try again.",
    },
    payment: {
        useSavedCard: "Use your saved card",
        useNewCard: "Use a new card",
        replaceDefaultCard: "Replace your default card",
        payInvoice: "Pay using invoice",
        invoiceMe: "Invoice me",
        chooseAndContinue: "Choose and continue",
        audienceDetailTile: "Details of your audience",
        paymentMethod: "Payment method",
        total: "Total",
        totalCharges: "Total Charges",
        creditCardDetails: "Credit card details",
        primaryCreditCard: "Primary Credit Card",
        newCardDetails: "New Card Details",
        processing: "Processing",
        loading: "Loading",
        authorizeEvorra:
            "I authorize Evorra to send instructions to the financial institution that issued my card to take payments from my card account in accordance with the terms of my agreement with you.",
        alertExplanation:
            "We will charge your card with a 0 transaction to check it is a valid payment method. You might get a VbV or 3D secure dialog, please confirm the transaction",
        billingAddress: {
            title: "Billing Address",
            street: "Street",
            city: "City",
            country: "Country",
            state: "State",
            zipPost: "Zip / Postal Code",
        },
    },
    dsp: {
        enablementChannel: "Enablement Channel",
        enablementChannelId: "Enablement Channel Id",
        enablementSetup: "Enablement setup",
        enablementOption: "Enablement Option",
        enablementOptionConfiguration: "Enablement Option Configuration",
        advertizerId: "Advertiser ID",
        BuyerId: "Buyer ID",
        maxSize: "Max. Size",
        cmp: "CPM",
        desiredNbProfile: "Desired Unique Profiles",
        desiredSpend: "Desired spend",
        campaignEndDate: "Refresh Audience Until",
        minimum: "Minimum",
        total: "Total",
        continue: "Continue",
        cancel: "cancel",
        dspTable: {
            enablementOptionTip:
                "Below are all the channels you have integrated to enable audiences. Each channel can potentially have several methods for audience activation which you can fully control",
            enablementChannelIdTip:
                "This is the Seat ID where your selected audiences will either be sent to, or be able to be activated on. Admins can change or update this Seat ID via the Enablement Setup tab.",
            maxSizeTip:
                "This is the maximum number of profiles you can send to each channel. These profiles are optimised for uniqueness. Evorra will send as many unique profiles as possible along with their associated IDs.",
            enablementChannel: "dsp.dspTable.enablementChannel",
            seatName: "dsp.dspTable.seatName",
        },
    },
    dspChannelParameters: {
        addEnablementChannel: "Add Enablement Channel",
        addChannel: "Add New Channel",
        addChannelPlaceHolder: "Add Channel",
        noMoreChannels: "No more channels available",
        seatName: "Seat Name",
        wizardSelectChannelTitle: "Channels",
        wizardSelectChannelDescription: "Select a channel from the drop down menu below.",
        wizardSelectOptionsTitle: "Enablement Options Available",
        wizardSelectOptionsDescription: "These following methods of audience enablement on your selected channel will be automatically activated.",
        wizardSelectSspTitle: "Allow audiences to be sent via",
        wizardSelectSspDescription: "Confirm the methods you would like to send audiences via this channel",
        wizardStep: "Step ",
        wizardStepChannel: ". - Choose a channel",
        wizardStepIdentifier: ". - Enter identifier",
        wizardStepSSPConfirm: ". - Enablement methods",
        wizardButtonFinalize: "Finalize & Validate",
        wizardValidationError: "The ID number could not be validated.\r\nPlease check the number and try again.",
        partnerId_dv360_desc: "This ID will be used for Pubmatic to push the audience at partner level.",
    },
};

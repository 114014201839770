import {Box, Button, Skeleton, Typography} from "@mui/material";
import React from "react";
import {useSegmentBuilderStore} from "../SegmentBuilderStore";
import {getTotalFromIdsCountList, getTotalIdsForSegmentsList} from "../Segments.utils";
import {profilesFormatter} from "../../../_configuration/formaters";
import {IExternalSegment} from "../../../application/entities/dataTypes/externalSegment";
import AnalyticsOutlinedIcon from "@mui/icons-material/AnalyticsOutlined";

export const SegmentVolumeTotal = () => {

    const dspCampaignInfo = useSegmentBuilderStore((state) => state.dspCampaignInfo)
    const channelsSelected = useSegmentBuilderStore((state) => state.channelsSelected)
    const sidebarLoading = useSegmentBuilderStore((state) => state.sidebarLoading)
    const displaySidePanel = useSegmentBuilderStore(st => st.displaySidePanel)
    const selectedSegments = useSegmentBuilderStore((state) => state.selectedSegments)

    const isGroupActive = (groupCode: string) => {
        if (channelsSelected === undefined || !channelsSelected?.length) return true
        return channelsSelected.some(channel => channel.includes(groupCode))
    }

    const activeSegmentList = dspCampaignInfo?.filter(dsp => isGroupActive(dsp.groupCode))?.map(dsp => dsp.dspItems.map(dspItem => dspItem.externalSegments).flat()).flat()

    const totalIds = getTotalIdsForSegmentsList(selectedSegments as IExternalSegment[])


    return <>
        <Box sx={{display: 'flex', alignItems: 'center', gap: '5px'}}>
            <Typography variant="h4" fontSize="16px">
                Ids Volume Max:
            </Typography>

            {sidebarLoading ? (
                <Skeleton variant="text" width={90} height={24} sx={{borderRadius: '7px'}}/>
            ) : (
                <>
                    <Typography variant="h4" fontSize="16px">
                        {profilesFormatter.format(getTotalFromIdsCountList(totalIds))}
                    </Typography>
                    {(selectedSegments && selectedSegments.length > 0) && <>
                        <Button variant="text" startIcon={<AnalyticsOutlinedIcon/>}
                                onClick={() => useSegmentBuilderStore.setState({displaySidePanel: !displaySidePanel})}>
                            {displaySidePanel ? 'Hide Details' : 'Show Details'}
                        </Button>
                    </>
                    }
                </>

            )
            }

        </Box>
    </>
}
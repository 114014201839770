import {create} from "zustand";
import {devtools} from "zustand/middleware";
import {Auth} from "aws-amplify";
import {CognitoUser} from "amazon-cognito-identity-js";
import {AuthException} from "../application/utils/exceptions.utils";
import {evorraApi} from "../application/entities/api/apiService";
import {IDoRedefinePasswordArgs, IDoRetrievePasswordArgs} from "../redux/slices/auth.slices";
import {BackendError} from "../types/backendError";
import {mountStoreDevtool} from "simple-zustand-devtools";

interface IDoSignInArgs {
    email: string;
    password: string;
}

export interface IDoUserInvitationArgs {
    email: string;
    username: string;
    family_name: string;
    company: string;
    companyUrl: string;
    password: string;
    tempPassword: string;
    given_name: string;
    phoneNumber?: string;
    marketingOptIn?: boolean;
    analyticsOptIn?: boolean;
    termsAndConditionsAccepted?: boolean;
}

export interface IDoCheckTempPasswordArgs {
    email: string;
    tempPassword: string;
}

export interface IDoSetNewPasswordArgs {
    user: CognitoUser;
    password: string;
}

type AuthStoreType = {
    loggedIn: boolean;
    challengeName?:
        | "SMS_MFA"
        | "SOFTWARE_TOKEN_MFA"
        | "SELECT_MFA_TYPE"
        | "MFA_SETUP"
        | "PASSWORD_VERIFIER"
        | "CUSTOM_CHALLENGE"
        | "DEVICE_SRP_AUTH"
        | "DEVICE_PASSWORD_VERIFIER"
        | "ADMIN_NO_SRP_AUTH"
        | "NEW_PASSWORD_REQUIRED"; // replace signupStep
    cognitoUser?: CognitoUser | null;
    error?: any;
    showError?: boolean;
    password?: string | undefined;
    userCheckingState?: "pending" | "done";
    mfaSetupRequired: boolean;
    actions: {

        doRetrievePassword: (args: IDoRetrievePasswordArgs) => Promise<any>; // {flowType:'FORGOT_PASSWORD' | 'USER_ACTIVATION'}
        doRedefinePassword: (args: IDoRedefinePasswordArgs) => void | any;

    };
};


export const authStore = create<AuthStoreType>()(
    devtools((set, get) => ({
        loggedIn: false,
        challengeName: undefined,
        cognitoUser: null,
        error: undefined,
        userCheckingState: undefined,
        mfaSetupRequired: false,
        actions: {


            doRetrievePassword: async (args) => {
                return evorraApi
                    .route(`/cognito/forgotPasswordOrResendEmail`)
                    .post({email: args.email})
                    .then((res) => {
                        return res;
                    })
                    .catch((error) => {
                        throw new BackendError(error);
                    });
            },
            doRedefinePassword: async (args) => {
                const {email, code, password} = args;
                return Auth.forgotPasswordSubmit(email, code, password)
                    .then((res) => res)
                    .catch((error) => {
                        set({
                            error: error,
                            showError: true,
                            userCheckingState: "done",
                        });
                        throw AuthException(error);
                    });
            },

        },
    }))
);

//Display debug in React Dev Tools
if (process.env.NODE_ENV === "development") {
    mountStoreDevtool("authStore", authStore);
}

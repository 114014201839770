import {createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {apiService} from 'application/entities/api/apiService';
import {authService} from 'application/services/auth.service';
import {CognitoUser, CognitoUserSession} from 'amazon-cognito-identity-js';
import {Auth, Cache} from 'aws-amplify';
import {RootState} from 'redux/store/rootReducer';
import {useDispatch, useSelector} from 'react-redux';
import {Authorities, IAccounts, ICompanyUsers} from 'types';
import {AUTHUser} from '../../application/utils/AuthUser';
import {AuthException} from '../../application/utils/exceptions.utils';
import {objectApi} from 'application/entities/dataApi';
import QRCode from 'qrcode';

export interface IAuthStateUser {
    id: number;
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    notes: string;
    role: string;
    permission: string;
    account: Partial<IAccounts>;
    authorities: Authorities[];
    fullName: string;
    jhiPrimary: boolean;
    jobTitle: string;
    company: Partial<IAccounts>;
    accountId: number;
    phone: number;
    primary: boolean;
    marketingOptIn: boolean;
    analyticsOptIn: boolean;
    termsAndConditionsAccepted: boolean;
}

export interface IAuthState {
    loggedIn: boolean;
    username: string | undefined;
    loggedInDateTime?: Date | undefined;
    //@deprecated
    signupStep?: any; // delete
    challengeName?: 'SMS_MFA' | 'SOFTWARE_TOKEN_MFA' | 'SELECT_MFA_TYPE' | 'MFA_SETUP' | 'PASSWORD_VERIFIER' | 'CUSTOM_CHALLENGE' | 'DEVICE_SRP_AUTH' | 'DEVICE_PASSWORD_VERIFIER' | 'ADMIN_NO_SRP_AUTH' | 'NEW_PASSWORD_REQUIRED'; // replace signupStep
    user: Partial<ICompanyUsers> | undefined;
    cognitoUser: any;
    showError?: boolean;
    error?: any;
    password?: string | undefined;
    userCheckingState?: 'pending' | 'done';
    mfaRequired: boolean;
    mfaCode: string;
    mfaEnabled: boolean;
    mfaSetupInProgress: boolean;
    mfaSetupSecret?: string;
    mfaQRCode?: string;
    mfaSetupRequired?: boolean;
}

export interface IDoSignInArgs {
    email: string;
    password: string;
}

export interface IDoRetrievePasswordArgs {
    email: string;
}

export interface IDoRedefinePasswordArgs {
    email: string;
    code: string;
    password: string;
}

export interface IDoUserInvitationStep1 {
    email: string;
    tempPassword: string;
}

export interface IDoUserInvitationStep2 {
    user: CognitoUser;
    password: string;
    firstname?: string;
    lastname?: string;
}

export interface IDoUserInvitationArgs {
    email: string;
    username: string;
    family_name: string;
    company: string;
    companyUrl: string;
    password: string;
    tempPassword: string;
    given_name: string;
    phoneNumber?: string;
    marketingOptIn?: boolean;
    analyticsOptIn?: boolean;
    termsAndConditionsAccepted?: boolean;
}

const initialState: IAuthState = {
    loggedIn: false,
    username: undefined,
    loggedInDateTime: undefined,
    signupStep: undefined,
    challengeName: undefined,
    user: undefined,
    cognitoUser: null,
    error: undefined,
    userCheckingState: undefined,
    mfaRequired: false,
    mfaCode: '',
    mfaEnabled: false,
    mfaSetupInProgress: false,
    mfaSetupSecret: undefined,
    mfaQRCode: undefined,
    mfaSetupRequired: false,
};

interface IVerifyMfaArgs {
    cognitoUser: CognitoUser;
    code: string;
}

interface IVerifyMfaSetupArgs {
    code: string;
}

const searchLoggedInUser = () => {
    return apiService
        .entity('companyUsers')
        .find('loggedInUser')
        .fetch();
};

const doSignin = createAsyncThunk(
    'auth/doSignin',
    async (args: IDoSignInArgs, thunkAPI) => {
        try {
            const res = await Auth.signIn(args.email, args.password);


            if (res.challengeName === 'SMS_MFA' || res.challengeName === 'SOFTWARE_TOKEN_MFA') {

                return thunkAPI.rejectWithValue({mfaRequired: true, cognitoUser: res, mfaEnabled: true});
            }

            const session = res.signInUserSession;

            if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
                throw AuthException({
                    code: res.challengeName,
                    message: 'Un nouveau mot de passe est requis',
                    name: 'NEW_PASSWORD_REQUIRED'
                });
            }

            if (!session?.getIdToken()) throw AuthException({
                code: '404',
                message: 'Une erreur est survenue',
                name: 'signing error'
            });

            const {data} = await searchLoggedInUser();

            let isMfaRequired = !data?.isMfaSetupDone && data?.mfaMandatory

            return {
                cognitoUser: res,
                user: data as Partial<ICompanyUsers>,
                isMfaRequired: isMfaRequired
            };

        } catch (error: any) {
            if (error.mfaRequired) {
                return thunkAPI.rejectWithValue(error);
            }
            throw AuthException(error);
        }
    },
);

const verifyMfaCode = createAsyncThunk(
    'auth/verifyMfaCode',
    async (args: IVerifyMfaArgs, thunkAPI) => {
        try {
            const user = await Auth.confirmSignIn(
                args.cognitoUser,
                args.code,
                'SOFTWARE_TOKEN_MFA' // ou 'SOFTWARE_TOKEN_MFA' selon le cas
            );

            const session = user.signInUserSession;

            if (!session?.getIdToken()) throw AuthException({
                code: '404',
                message: 'Une erreur est survenue',
                name: 'signing error'
            });

            const {data} = await searchLoggedInUser();

            return {
                cognitoUser: user,
                user: data as Partial<ICompanyUsers>,
            };
        } catch (error: any) {
            throw AuthException(error);
        }
    },
);

const initializeMfaSetup = createAsyncThunk(
    'auth/initializeMfaSetup',
    async (_, thunkAPI) => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const secret = await Auth.setupTOTP(user);
            const email = user.attributes.email

            //Qr code Generation
            const generateQrCodeUrl = (username: string, appName: string, secret: string): string => {
                const otpauth = `otpauth://totp/${appName}:${username}?secret=${secret}&issuer=${appName}`;
                return otpauth;

            };
            const qrCodeUrl = generateQrCodeUrl(email, 'Evorra', secret);
            const qrCodeImage = await QRCode.toDataURL(qrCodeUrl);


            return {secret, qrCodeImage};
        } catch (error: any) {
            throw AuthException(error);
        }
    }
);

const verifyMfaSetup = createAsyncThunk(
    'auth/verifyMfaSetup',
    async (args: IVerifyMfaSetupArgs, thunkAPI) => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.verifyTotpToken(user, args.code);
            await Auth.setPreferredMFA(user, 'TOTP');

            return true;
        } catch (error: any) {
            throw AuthException(error);
        }
    }
);

const enableSmsMfa = createAsyncThunk(
    'auth/enableSmsMfa',
    async (_, thunkAPI) => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            await Auth.setPreferredMFA(user, 'SOFTWARE_TOKEN_MFA');

            return true;
        } catch (error: any) {
            throw AuthException(error);
        }
    }
);

const disableMfa = createAsyncThunk(
    'auth/disableMfa',
    async (_, thunkAPI) => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            user.setUserMfaPreference({
                PreferredMfa: false,
                Enabled: false,
            }, {
                PreferredMfa: false,
                Enabled: false,
            }, (err: any, result: any) => {
                if (err) {
                    return false;
                }
                return true;
            });


            return true;
        } catch (error: any) {
            throw AuthException(error);
        }
    }
);

const doRetrievePassword = createAsyncThunk(
    'auth/doRetrievePassword',
    async (args: IDoRetrievePasswordArgs, thunkAPI) => {
        return await Auth.forgotPassword(args.email)
            .then((res) => {
                return res;
            })
            .catch((error) => {
                throw AuthException(error);
            });
    },
);

const doRedefinePassword = createAsyncThunk(
    'auth/doRedefinePassword',
    async (args: IDoRedefinePasswordArgs, thunkAPI) => {
        return await authService.redefinePassword(args);
    },
);

const doUserInvitationStep1 = createAsyncThunk(
    'auth/doUserInvitationStep1',
    async (args: IDoUserInvitationStep1, thunkAPI): Promise<CognitoUser | any> => {

        try {
            return Auth.signIn(args.email, args.tempPassword)
                .then((user) => {
                    return user;
                })
                .catch((error) => {
                    throw AuthException(error);
                });
        } catch (error: any) {
            return AuthException(error);
        }


    },
);

const doUserInvitationStep2 = createAsyncThunk(
    'auth/doUserInvitationStep2',
    async (args: IDoUserInvitationStep2, thunkAPI): Promise<{
        user: Partial<ICompanyUsers>,
        cognitoUser: CognitoUser | any
    }> => {

        return await Auth.completeNewPassword(args.user, args.password)
            .then((userNew) => {
                // at this time the user is logged in if no MFA required
                return Auth.currentAuthenticatedUser()
                    .then(async (userCognito: CognitoUser) => {
                        const {data} = await searchLoggedInUser();

                        //Complete firstname and lastname
                        data.firstName = args.firstname;
                        data.lastName = args.lastname;
                        const userApi = new objectApi.companyUsers();
                        userApi.update(data.id, {firstName: data.firstName, lastName: data.lastName});
                        return {
                            cognitoUser: userCognito,
                            user: data as Partial<ICompanyUsers>,
                        };
                    })
                    .catch((error) => {
                        throw AuthException(error);
                    });
            })
            .catch((error) => {
                throw AuthException(error);
            });
    },
);

const doUserInvitation = createAsyncThunk(
    'auth/doUserInvitation',
    async (args: IDoUserInvitationArgs, thunkAPI) => {

        return await authService
            .userInvitation(args)
            .then(async (res) => {
                const {data} = await searchLoggedInUser();

                return {
                    cognitoUser: res,
                    user: data as Partial<ICompanyUsers>,
                };
            });

    },
);
// reload info on user, recalled on account or user self changes
const doRefreshUser = createAsyncThunk(
    'auth/doUserRefresh',
    async () => {

        return await searchLoggedInUser();

    },
);

const doSignOut = createAsyncThunk(
    'auth/doSignOut',
    async (args: undefined, thunkAPI) => {
        localStorage.clear();
        const response = await authService.signOut();
        return {
            response, ...{
                mfaEnabled: false,
                mfaRequired: false,
                mfaCode: '',
                mfaSetupInProgress: false,
                mfaSetupSecret: undefined,
                mfaQRCode: undefined,
                loggedIn: false,
                cognitoUser: undefined,
                user: undefined,
                mfaSetupRequired: false,
            }
        };
    },
);

const refreshUserSession = createAsyncThunk(
    'auth/refreshUserSession',
    (thunkAPI) => {
        return authService.getCurrentSession()
            .then((res: CognitoUserSession) => {
                return searchLoggedInUser().then((loggedInUser) => {
                    return {cognitoUser: res, userLoggedIn: loggedInUser?.data};
                })
            });
    },
);

const forceRefreshSession = createAsyncThunk(
    'auth/forceRefreshSession',
    async (_, thunkAPI) => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const currentSession: CognitoUserSession = await Auth.currentSession();

            const refreshToken = currentSession.getRefreshToken();

            const newSession: CognitoUserSession = await new Promise((resolve, reject) => {
                user.refreshSession(refreshToken, (err: any, session: any) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve(session);
                    }
                });
            });

            const {data} = await searchLoggedInUser();

            return {
                cognitoUser: newSession,
                user: data as Partial<ICompanyUsers>,
            };
        } catch (error: any) {
            throw AuthException(error);
        }
    }
);


const authSlice = createSlice({
    name: 'authSlice',
    initialState,
    reducers: {
        resetError(state) {
            state.showError = false;
        },
        setMfaCode(state, action: PayloadAction<string>) {
            state.mfaCode = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(doSignin.fulfilled, (state, action) => {
                // Gestion de la connexion réussie sans MFA
                const {cognitoUser, user, isMfaRequired} = action.payload;

                const session = cognitoUser.signInUserSession;

                Cache.setItem('userAttributes', user);
                Cache.setItem('tokens', {
                    idToken: session.getIdToken(),
                    refreshToken: session.getRefreshToken(),
                    accessToken: session.getAccessToken(),
                });

                if (isMfaRequired) {
                    state.mfaSetupRequired = true
                } else {
                    state.loggedIn = session.isValid();
                }
                state.cognitoUser = cognitoUser;
                state.user = user;
                AUTHUser.setUser(user as ICompanyUsers);
                state.userCheckingState = 'done';
            })
            .addCase(doSignin.rejected, (state, action: any) => {
                if (action.payload?.mfaRequired) {
                    state.mfaRequired = true;
                    state.cognitoUser = action.payload.cognitoUser;
                } else {
                    Cache.removeItem('userAttributes');
                    Cache.removeItem('tokens');
                    state.loggedIn = false;
                    state.error = action.error;
                    state.showError = true;
                }
                state.userCheckingState = 'done';
            })
            .addCase(verifyMfaCode.fulfilled, (state, action) => {
                const {cognitoUser, user} = action.payload;

                Cache.setItem('userAttributes', user);
                state.loggedIn = true;
                state.cognitoUser = cognitoUser;
                state.user = user;
                AUTHUser.setUser(user as ICompanyUsers);
                state.mfaRequired = false;
                state.userCheckingState = 'done';
            })
            .addCase(verifyMfaCode.rejected, (state, action: any) => {
                state.error = action.error;
                state.showError = true;
                state.userCheckingState = 'done';
            })
            .addCase(initializeMfaSetup.pending, (state) => {
                state.mfaSetupInProgress = true;
            })
            .addCase(initializeMfaSetup.fulfilled, (state, action) => {
                state.mfaSetupInProgress = false;
                state.mfaSetupSecret = action.payload.secret;
                state.mfaQRCode = action.payload.qrCodeImage;
            })
            .addCase(initializeMfaSetup.rejected, (state, action: any) => {
                state.mfaSetupInProgress = false;
                state.error = action.error;
                state.showError = true;
            })
            .addCase(verifyMfaSetup.fulfilled, (state) => {
                state.mfaEnabled = true;
                state.mfaSetupRequired = false
                state.loggedIn = true
                state.mfaRequired = false;
                state.userCheckingState = 'done';
                state.mfaQRCode = undefined
                state.mfaSetupSecret = undefined
            })
            .addCase(verifyMfaSetup.rejected, (state, action: any) => {
                state.error = action.error;
                state.showError = true;
            })
            .addCase(enableSmsMfa.fulfilled, (state) => {
                state.mfaEnabled = true;
            })
            .addCase(enableSmsMfa.rejected, (state, action: any) => {
                state.error = action.error;
                state.showError = true;
            })

            // Désactiver le MFA
            .addCase(disableMfa.fulfilled, (state) => {
                state.mfaEnabled = false;
            })
            .addCase(disableMfa.rejected, (state, action: any) => {
                state.error = action.error;
                state.showError = true;
            })
            .addCase(doRetrievePassword.fulfilled.type, (state, action) => {
            })
            .addCase(doRetrievePassword.rejected.type, (state, action: any) => {
                state.userCheckingState = 'done';
                state.error = action.error;
                state.showError = true;
            })
            .addCase(doRedefinePassword.fulfilled.type, (state, action) => {
                state.userCheckingState = 'done';
            })
            .addCase(doRedefinePassword.rejected.type, (state, action: any) => {
                state.error = action.error;
                state.showError = true;
                state.userCheckingState = 'done';
            });
        // do UserInvitation Step1
        builder.addCase(
            doUserInvitationStep1.fulfilled.type,
            (state, action: PayloadAction<CognitoUser>) => {
                const {payload} = action;
                state.challengeName = 'NEW_PASSWORD_REQUIRED';
                state.cognitoUser = payload;
            },
        );
        builder.addCase(
            doUserInvitationStep1.rejected.type,
            (state, action: any) => {
                const {error} = action;

                if (error) {
                    state.error = error
                    state.showError = true
                }
            },
        );
        builder.addCase(
            doUserInvitationStep2.fulfilled.type,
            (state, action: PayloadAction<{ cognitoUser: CognitoUser, user: Partial<ICompanyUsers> }>) => {
                const {payload} = action;
                const {cognitoUser, user} = payload;

                if (payload) {
                    // cache entry, needed for refreshToken ?
                    Cache.setItem('userAttributes', user);
                    state.loggedIn = true;
                    state.cognitoUser = cognitoUser;
                    state.signupStep = undefined; // cognitoUser.challengeName;
                    state.challengeName = undefined; // cognitoUser.challengeName;
                    state.user = user;
                }

                state.userCheckingState = 'done';
                // consider logged in
            },
        );
        builder.addCase(
            doUserInvitation.fulfilled.type,
            (state, action: PayloadAction<any>) => {
                const {payload} = action;
                const {cognitoUser, user} = payload;

                if (payload) {
                    // cache entry
                    Cache.setItem('userAttributes', user);
                    state.loggedIn = true;
                    state.cognitoUser = cognitoUser;
                    state.signupStep = cognitoUser.challengeName;
                    state.challengeName = cognitoUser.challengeName;
                    state.user = user;
                }

                state.userCheckingState = 'done';
                // consider logged in
            },
        );
        builder.addCase(doUserInvitation.rejected.type, (state, action: any) => {
            state.error = action.error;
            state.showError = true;
            state.userCheckingState = 'done';
        });
        //
        builder.addCase(
            refreshUserSession.fulfilled.type,
            (state, action: PayloadAction<any>) => {
                const {cognitoUser, userLoggedIn} = action.payload;
                const user = cognitoUser.getIdToken().payload;

                if (user && Cache.getItem('userAttributes')) {
                    // retrieve user from storage
                    state.user = Cache.getItem('userAttributes');
                    state.loggedIn = true;
                }

                let isMfaRequired = !userLoggedIn?.isMfaSetupDone && userLoggedIn?.mfaMandatory


                state.mfaSetupRequired = isMfaRequired
                state.cognitoUser = user;
                state.userCheckingState = 'done';
            },
        );
        builder.addCase(refreshUserSession.pending.type, (state, action: any) => {
            state.userCheckingState = 'pending';
        });
        builder.addCase(refreshUserSession.rejected.type, (state, action: any) => {
            state.loggedIn = false;
            state.userCheckingState = 'done';
        });
        //
        builder.addCase(
            doRefreshUser.fulfilled.type,
            (state, action: PayloadAction<any>) => {
                // put user in singleton
                if (action.payload.data.id) AUTHUser.setUser(action.payload.data);
            },
        );
        builder.addCase(
            doSignOut.fulfilled.type,
            (state, action: PayloadAction<any>) => {
                state.loggedIn = false;
                state.mfaEnabled = false;
                state.mfaRequired = false;
                state.mfaSetupRequired = false;
                state.mfaSetupInProgress = false;
                state.mfaSetupSecret = undefined;
                state.mfaQRCode = undefined;
                state.cognitoUser = undefined;
                state.user = undefined;
            },
        );
        builder.addCase(forceRefreshSession.fulfilled, (state, action) => {
            const {cognitoUser, user} = action.payload;
            Cache.setItem('userAttributes', user);
            Cache.setItem('tokens', {
                idToken: cognitoUser.getIdToken(),
                refreshToken: cognitoUser.getRefreshToken(),
                accessToken: cognitoUser.getAccessToken(),
            });

            state.loggedIn = cognitoUser.isValid();
            state.cognitoUser = cognitoUser;
            state.user = user;
            AUTHUser.setUser(user as ICompanyUsers);
            state.userCheckingState = 'done';
        })
            .addCase(forceRefreshSession.rejected, (state, action: any) => {
                state.error = action.error;
                state.showError = true;
                state.userCheckingState = 'done';
            });
    },
});

function AuthActions() {
    const dispatch = useDispatch<any>();

    return {
        resetError: () => {
            authSlice.actions.resetError();
        },
        setMfaCode: (code: string) => {
            dispatch(authSlice.actions.setMfaCode(code));
        },
        doSignin: async (args: IDoSignInArgs) => {
            return dispatch(doSignin(args));
        },
        verifyMfaCode: async (args: IVerifyMfaArgs) => {
            return dispatch(verifyMfaCode(args));
        },
        initializeMfaSetup: async () => {
            return dispatch(initializeMfaSetup());
        },
        verifyMfaSetup: async (args: IVerifyMfaSetupArgs) => {
            return dispatch(verifyMfaSetup(args));
        },
        enableSmsMfa: async () => {
            return dispatch(enableSmsMfa());
        },
        disableMfa: async () => {
            return dispatch(disableMfa());
        },
        /** @deprecated */
        doRetrievePassword: async (args: IDoRetrievePasswordArgs) => {
            return dispatch(doRetrievePassword(args));
        },
        doRedefinePassword: async (args: IDoRedefinePasswordArgs) => {
            return dispatch(doRedefinePassword(args));
        },
        doUserInvitation: async (args: IDoUserInvitationArgs) => {
            return dispatch(doUserInvitation(args));
        },
        doUserInvitationStep1: async (args: IDoUserInvitationStep1) => {
            return dispatch(doUserInvitationStep1(args));
        },
        doUserInvitationStep2: async (args: IDoUserInvitationStep2) => {
            return dispatch(doUserInvitationStep2(args));
        },
        doSignOut: async () => {
            return dispatch(doSignOut());
        },
        doRefreshSession: async () => {
            return dispatch(refreshUserSession());
        },
        doRefreshUser: async () => {
            return dispatch(doRefreshUser());
        },
        forceRefreshSession: async () => {
            return dispatch(forceRefreshSession());
        },
    };
}

export function useAuthState() {
    return useSelector((state: RootState) => state.authSlice || {});
}

export const useAuthActions = AuthActions;

export default authSlice.reducer;

import React, {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
  authInputUserInvitation,
  authInputUserSigninInfo,
  emailField,
  firstnameField,
  lastnameField,
  tempPasswordField,
} from '../../../../_configuration/auth.config';
import {InputAdornment, TextField} from '@mui/material';
import {useTranslation} from 'react-i18next';
import {useForm} from 'react-hook-form';

import {
  AuthButtonSubmit,
  AuthHeader,
  AuthSignZone,
  AuthTickBox,
  AuthTStepBox,
  HtmlForm,
  InputWrapper,
} from '../authentication.styled';
import {AppStepper} from 'components/ui/AppStepper';
import {AppSwitch} from 'components/form/AppSwitch';
import {
  BoxRow,
  ColumnsMiddleCenter,
  ElementRowCentered,
  ElementRowHContainer,
  H5,
  HeaderZoneIconBar,
  HeaderZoneMessage,
  HeaderZoneTitle,
} from 'components/ui/AppElements';
import {useAuthActions} from 'redux/slices/auth.slices';
import {useSelector} from 'react-redux';
import {RootState} from 'redux/store/rootReducer';
import AuthStrength from '../../../live/AuthStrength';
import {AppIcon} from '../../../ui/AppIcon';
import {InputLabel} from '../../../form/Input';
import {StyledCheckbox} from '../../../form/CheckBox';
import {AppTip} from "pages/AudienceBuilder/components/AudienceBuilderAppTip";


interface ISigninState {
    email: string;
    password: string;
    tempPassword: string;
    username?: string;
    given_name?: string;
    family_name?: string;
    firstname?: string;
    lastname?: string;
    company?: string;
    companyUrl?: string;
    marketingOptIn?: boolean;
    analyticsOptIn?: boolean;
    termsAndConditionsAccepted?: boolean;

    [key: string]: any;
}

const UserInvitation = () => {

    const {t} = useTranslation();

    const urlParams = useParams<{ email: string, password: string }>() as { email: string, password: string };
    const authActions = useAuthActions();

    const {
        handleSubmit,
        control,
        register,
        getValues,
        formState: {isValid, errors},
    } = useForm({mode: 'onChange'});

    const authSlice = useSelector((state: RootState) => state.authSlice || {});

    console.log('authSlice', authSlice)

    // set to 1 to debug second step
    const [signinStep, setSigninStep] = React.useState<number>(0);

    const doneInvite = React.useRef<boolean>(false);
    const navigate = useNavigate();

    const userEmail = urlParams?.email ?? '';
    const userPassword = urlParams?.password;
    emailField.props.value = userEmail;


    const [signupValuesState, setSigninValuesState] =
        React.useState<ISigninState>({
            email: userEmail,
            tempPassword: '',
            password: '',
            password_2: '',
            firstname: '',
            lastname: '',
            username: userEmail,
            given_name: userEmail,
            family_name: 'Smith',
            company: 'evorra',
            companyUrl: 'http://www.evorra.com',
            termsAndConditionsAccepted: false
        });

    //
    const formj = [authInputUserSigninInfo, authInputUserInvitation];

    const messageTitles = [
        t('auth.userInvitation.title.step1'),
        t(`auth.userInvitation.title.step2`),
    ];
    const messageText = [
        t('auth.userInvitation.message.step1'),
        t('auth.userInvitation.message.step2'),
    ];
    const submitText = [
        t('auth.userInvitation.submitText.step1'),
        t('auth.userInvitation.submitText.step2'),
    ];

    React.useEffect(() => {
        // clear errors showing on enter
        authActions.resetError();
    }, []);

    useEffect(() => {

        if (authSlice.password) {

            setSigninValuesState(function (state) {
                return {
                    ...state,
                    tempPassword: authSlice.password ?? ''
                };
            });
        }
        if (userPassword) {
            setSigninValuesState(function (state) {
                return {
                    ...state,
                    tempPassword: userPassword
                };
            });
        }

        if (authSlice.challengeName === 'NEW_PASSWORD_REQUIRED' && signinStep === 0) {
            // user details should be known from database call
            // and are present in redux slice
            // password should be in authSlice or in route state

            setSigninValuesState(function (state) {
                return {
                    ...state,
                    username: userEmail,
                    given_name: userEmail, // authSlice?.user?.firstName,
                    family_name: authSlice?.user?.lastName,
                    company: authSlice?.user?.account?.companyName,
                    companyUrl: authSlice?.user?.account?.website,
                };
            });


        }
    }, [signinStep, authSlice]);


    const handleFormChange = (event: any) => {


        const inputName = event.target.name;
        let inputValue: unknown = event.target.value;


        if (['email', 'termsAndConditionsAccepted', 'marketingOptIn', 'analyticsOptIn'].includes(inputName)) {
            return;
        }

        setSigninValuesState(function (state) {
            return {
                ...state,
                [inputName]: inputValue,
            };
        });
    };

    const handleSubmitStep = (event: any) => {
        // event.preventDefault();
        //
        switch (signinStep) {
            case 0:
                authActions.doUserInvitationStep1(signupValuesState)
                    .then((res) => {
                        if (!res.error) setSigninStep(1);
                    });
                break;
            case 1:
                authActions.doUserInvitationStep2({
                    user: authSlice.cognitoUser,
                    password: signupValuesState.password,
                    firstname: signupValuesState.firstname,
                    lastname: signupValuesState.lastname
                } as any).then((res) => {
                    doneInvite.current = true;
                    // navigate to '/' the router will redirect to correct dashboard
                    navigate('/');
                });
                break;
            case 2:
                break;
        }

        return false;
    };

    const getPassWordForce = (force: number) => {

    };

    const onStepClick = (step: number) => {
        // cannot go forward
        if (step > signinStep) return;
        setSigninStep(step);
    };

    // todo : deduplicate from other comp

    const regexCrit = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&#.,;`^~])(?=.{8,})/;
    const regexOk = Boolean(regexCrit.test(signupValuesState.password));
    const showDupError = signupValuesState.password?.length && signupValuesState.password_2?.length && signupValuesState.password !== signupValuesState.password_2;
    const samePass = signupValuesState.password === signupValuesState?.password_2;
    const formStep1Disabled = !(regexOk && samePass && Boolean(signupValuesState['termsAndConditionsAccepted']));


    // todo : deduplicate from other comp
    const passCrit = 'Minimum length: \n' +
        '8 characters\n' +
        'Must contain: \n' +
        '1 upper and 1 lowercase letter\n' +
        'One special character (ie. @ & # % etc)\n' +
        'One number\n';
    return (
        <AuthSignZone>
            <AuthHeader>
                <HeaderZoneIconBar>
                    <AppIcon display={'inline'} icon={signinStep === 0 ? 'ActivateAccountIcon' : 'LoginIcon'}
                             fontSize={'medium'}/>
                </HeaderZoneIconBar>
                <HeaderZoneTitle>
                    <H5 display={'inline'}>{messageTitles[signinStep]}</H5>
                </HeaderZoneTitle>
                <HeaderZoneMessage>
                    <p>{messageText[signinStep]}</p>
                    {signinStep === 0 && <p>If you experience difficulties, contact your Evorra administrator</p>}
                </HeaderZoneMessage>
            </AuthHeader>
            <HtmlForm>
                <form onSubmit={handleSubmit(handleSubmitStep)} onChange={handleFormChange}>

                    {signinStep === 0 && <>
                        <InputWrapper key={'firstname'}>
                            <InputLabel>{firstnameField.label} {firstnameField.options.required ?
                                <Required/> : ''}</InputLabel>
                            <TextField
                                {...register(firstnameField.props.name, firstnameField.options)} {...firstnameField.props}
                                error={errors[firstnameField.props.name]}
                                helperText={errors[firstnameField.props.name]?.message}
                                style={{width: 224}}
                                inputProps={{'data-cy': 'input_firstname'}}
                            />
                        </InputWrapper>

                        <InputWrapper>
                            <InputLabel>{lastnameField.label} {firstnameField.options.required ?
                                <Required/> : ''}</InputLabel>
                            <TextField
                                {...register(lastnameField.props.name, lastnameField.options)} {...lastnameField.props}
                                error={errors[lastnameField.props.name]}
                                helperText={errors[lastnameField.props.name]?.message}
                                style={{width: 224}}
                                inputProps={{'data-cy': 'input_lastname'}}
                            />
                        </InputWrapper>
                    </>
                    }
                    <InputWrapper>
                        <InputLabel>{emailField.label} {emailField.options.required ? <Required/> : ''} </InputLabel>
                        <TextField
                            {...register(emailField.props.name, emailField.options)} {...emailField.props}
                            error={errors[emailField.props.name]}
                            helperText={errors[emailField.props.name]?.message}
                            style={{width: 224}}
                            inputProps={{sx: {paddingRight: '0px !important'}}}
                            InputProps={{
                                endAdornment: <InputAdornment position="end" sx={{paddingRight: '0.6rem'}}><AppIcon
                                    color={'#3B4559'} fontSize="small" icon={'LockIcon'}/></InputAdornment>
                            }}
                        />
                    </InputWrapper>
                    {signinStep === 0 && <><InputWrapper>
                        <InputLabel>{tempPasswordField.label} <Required/></InputLabel>
                        <TextField
                            {...register(tempPasswordField.props.name, tempPasswordField.options)}{...tempPasswordField.props}
                            error={errors[tempPasswordField.props.name]}
                            helperText={errors[tempPasswordField.props.name]?.message}
                            style={{width: 224}}
                            autoComplete={'new-password'}
                            inputProps={{'data-cy': 'input_temp_password'}}
                        />
                    </InputWrapper></>}
                    {signinStep === 1 && <>
                        <InputLabel>
                  <span className={'flex-h flex-inline flex-align-middle'} style={{gap: '0.5rem'}}>
                      <div>Set new password <Required/></div>
                      <AppTip title={'Password Criteria'} text={passCrit}/>
                  </span>
                        </InputLabel>
                        <TextField
                            type={'password'}
                            name={'password'}
                            required={true}
                            value={signupValuesState['password']}
                            autoComplete={'new-password'}
                            onChange={(event) => {
                                event.preventDefault();
                            }}
                            style={{width: 224}}
                            inputProps={{readonly: true, 'data-cy': 'input_password'}}
                            error={Boolean(!regexOk) && signupValuesState?.password?.length > 0}
                            helperText={Boolean(!regexOk) && signupValuesState?.password?.length > 0 ? 'Not matching criteria' : undefined}
                        />
                        {Boolean(regexOk) && <>
                            <AuthStrength
                                password={signupValuesState['password'] ?? ''}
                                getForceCallback={getPassWordForce}
                            />

                        </>
                        }
                        <InputLabel>{'Re-enter new Password'} <Required/></InputLabel>
                        <TextField
                            type={'password'}
                            name={'password_2'}
                            required={true}
                            defaultValue={
                                signupValuesState['password_2']
                            }
                            autoComplete={'new-password'}
                            onChange={() => {
                            }}
                            style={{width: 224}}
                            error={Boolean(showDupError)}
                            placeholder={'Set new password'}
                            helperText={showDupError ? t('auth.noPasswordMatch') : undefined}
                            inputProps={{'data-cy': 'input_check_password'}}

                        />
                    </>}
                    <br/>
                    {signinStep === 1 && (
                        <>
                            <br/>
                            <ElementRowCentered>
                                <AuthTickBox>
                                    <AppSwitch
                                        name={'marketingOptIn'}
                                        label={'Marketing Opt-in'}
                                        checked={signupValuesState['marketingOptIn']}
                                        onChange={(event, checked) => {
                                            setSigninValuesState((curr: ISigninState) => {
                                                return {...curr, marketingOptIn: checked};
                                            });
                                        }}
                                    />
                                </AuthTickBox>
                            </ElementRowCentered>
                            <ElementRowCentered>
                                <AuthTickBox>
                                    <AppSwitch
                                        name={'analyticsOptIn'}
                                        label={'Analytics Opt-in'}
                                        checked={signupValuesState['analyticsOptIn']}
                                        onChange={(event, checked) => {
                                            setSigninValuesState((curr: ISigninState) => {
                                                return {...curr, analyticsOptIn: checked};
                                            });
                                        }}
                                    />
                                </AuthTickBox>
                            </ElementRowCentered>
                            <br/>
                            <ElementRowHContainer>
                                <BoxRow>
                                    <StyledCheckbox value={Boolean(signupValuesState['termsAndConditionsAccepted'])}
                                                    name={'termsAndConditionsAccepted'}
                                                    onChange={(event) => {
                                                        setSigninValuesState((curr: ISigninState) => {
                                                            return {
                                                                ...curr,
                                                                termsAndConditionsAccepted: event?.target?.checked
                                                            };
                                                        });
                                                    }}
                                                    checked={Boolean(signupValuesState['termsAndConditionsAccepted'])}/>
                                </BoxRow>
                                <BoxRow>
                                    <a style={{textDecoration: 'underline'}} target="_blank"
                                       href="https://evorra.com/privacy-data-policies/evorra-platform-terms-and-conditions/">{t('auth.termAndConditionApproval')}</a>
                                    <Required/>
                                </BoxRow>
                            </ElementRowHContainer>
                        </>
                    )}
                    {signinStep === 0 && <AuthButtonSubmit disabled={!isValid} type="submit">
                        {submitText[signinStep]}
                    </AuthButtonSubmit>}
                    {signinStep === 1 && <div><ColumnsMiddleCenter>
                        <AuthButtonSubmit disabled={formStep1Disabled}
                                          type="submit">{submitText[signinStep]}</AuthButtonSubmit>
                    </ColumnsMiddleCenter></div>}
                    <AuthTStepBox>
                        <AppStepper onStepClick={onStepClick} steps={2} activeStep={signinStep}/>
                    </AuthTStepBox>
                </form>
            </HtmlForm>
        </AuthSignZone>
    );
};

export {UserInvitation};

const Required = () => <span style={{color: '#DA2318'}}> *</span>;
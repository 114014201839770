import {Box, CircularProgress, Collapse} from '@mui/material';
import {Theme} from '@mui/material/styles';
import {styled} from '@mui/styles';
import {getDspAdditionalInfo} from 'application/utils/dsp.utils';
import {moneyFormatter} from 'application/utils/formatters.utils';
import React from 'react';
import {useLocalStorageState} from 'use-local-storage-state';
import {profilesFormatter} from '../../../_configuration/formaters';
import {objectApi} from '../../../application/entities/dataApi';
import {IAudiences} from '../../../application/entities/dataTypes/audiences';
import {TIcon} from '../../../assets/icons';
import {providerIconList} from '../../../assets/providerIcons';
import {AppSwitch} from '../../../components/form/AppSwitch';
import {AppIcon} from '../../../components/ui/AppIcon';
import {
    calculateCpmForSegment,
    getCPMFromSegmentsList,
    getCPMToDisplay,
    getTotalFromIdsCountList,
    getTotalIdsForSegmentsList
} from "../../SegmentBuilder/Segments.utils";
import {IExternalSegment} from "../../../application/entities/dataTypes/externalSegment";
import {useSegmentsParameters} from "../../../components/hooks/data/useSegmentsParameters";


export const ProviderStatus = (props: {
    audienceData: IAudiences,
    position?: Record<string, any>,
    isSegmentAudience: boolean
}) => {

    const {audienceData, isSegmentAudience} = props;
    const currency = audienceData?.displayCurrency

    const audienceApi = new objectApi.audiences();
    const {segmentParamsData, segmentParamsLoading} = useSegmentsParameters(audienceData.account.id)


    const [dspCampaignInfo, setDspCampaignInfo] = React.useState<IAudiences['dspCampaignInfo']>([]);
    const [state, setState] = useLocalStorageState<boolean>('prov-exp', false);
    const [states, setStates] = useLocalStorageState<Record<string, boolean>>('provider-status', {});

    const [loading, setLoading] = React.useState<boolean>(false);


    React.useEffect(() => {
            setLoading(true)
            // setDspCampaignInfo(audienceData.dspCampaignInfo.filter((dspInfo: any) => dspInfo.dspItems.some((x: any) => !x.hasError)));
            audienceApi.openAndRefreshAudience(audienceData.id)
                .then(res => {
                    if (res.data?.dspCampaignInfo) setDspCampaignInfo(res.data.dspCampaignInfo.filter((dspInfo: any) => dspInfo.dspItems.some((x: any) => !x.hasError)));
                    setLoading(false)
                })
                .catch(e => {
                    setLoading(false)
                });
        },
        [audienceData]);

    React.useEffect(() => {
            dspCampaignInfo.filter(dspInfo => dspInfo.dspItems.some(x => !x.hasError)).forEach((dspInfo) => {
                setStates(st => ({...st, [dspInfo.groupCode]: states?.[dspInfo.groupCode] ?? true}));
            });
        },
        [dspCampaignInfo]);

    React.useEffect(() => {
            if (Object.values(states).every(k => k)) {
                setState(true);
            }
            if (Object.values(states).every(k => !k)) {
                setState(false);
            }
        },
        [states]);

    const handleOpenCloseAll = () => {
        const sta = !state;
        setState(sta);
        dspCampaignInfo.forEach((dspInfo) => {
            setStates(st => ({...st, [dspInfo.groupCode]: sta}));
        });
    };

    const handleToggle = (groupCode: string) => {
        setStates(st => ({...st, [groupCode]: !(st[groupCode])}));
    };


    return <ProviderStatusContainer className={'flex-v'}>
        <DspLoader loading={loading}><CircularProgress style={{display: 'block', marginTop: '0.5rem'}}
                                                       size={24}/></DspLoader>
        <div className={'flex-h flex-align-right gap-small pad'}>
            <AppSwitch labelPlacement="start" checked={state} onChange={handleOpenCloseAll} label={'Expand all '}/>
        </div>
        <ProviderContentContainer className={'flex-main'}>
            {dspCampaignInfo?.filter(dspInfo => dspInfo.dspItems.some(x => !x.hasError))?.map((dspInfo) => {

                const icon = states?.[dspInfo.groupCode] ? 'Minus' : 'Plus';
                return <div>
                    <DspHeader className={'flex-h'} onClick={() => {
                        handleToggle(dspInfo.groupCode);
                    }}>
                        <div className={'iconCircle'}>
                            <AppIcon fontSize={'small'}
                                     icon={providerIconList?.[dspInfo.groupCode.replace('_group', '')] as TIcon ?? 'SettingsIcon'}/>
                        </div>
                        <Box className={'flex-main'}>
                            {dspInfo.groupName}
                        </Box>
                        <Box><AppIcon fontSize={'small'} icon={icon}/></Box>
                    </DspHeader>
                    {dspInfo.dspItems.filter(x => !x.hasError).map((dspItem) => {
                        const additionnalInfo = getDspAdditionalInfo(dspItem.code)
                        return <Collapse in={states[dspInfo.groupCode]} style={{transformOrigin: '0 0 0'}}>
                            <Box className={'flex-v'}>
                                <DspRow className={'flex-h'}>
                                    <div className={'flex-main bold'}>{additionnalInfo?.name ?? dspItem.name}</div>
                                    {dspItem.hasError &&
                                        <div className={'flex-h gap-small flex-align-middle border-b pad-l'}>
                                            <div>Configuration error</div>
                                            <div><AppIcon fontSize={'small'} color={'red'} icon={'WarningIcon'}/></div>
                                        </div>}
                                </DspRow>
                                {!isSegmentAudience && <>
                                    <DspRow className={'flex-h'}>
                                        <Bubble/>
                                        <DspLabel className={'flex-main'}>Unique Profiles available</DspLabel>
                                        <div>{dspItem.minSize > dspItem.maxSize ? '< Minimum' : profilesFormatter.format(dspItem.maxSize)}</div>
                                    </DspRow>
                                    <DspRow className={'flex-h'}>
                                        <Bubble color={'#FCA657'}/>
                                        <DspLabel className={'flex-main'}>Estimated CPM</DspLabel>
                                        <div>{moneyFormatter(dspItem.cpmConverted, {currency: currency})}</div>
                                    </DspRow>
                                </>}
                                {isSegmentAudience && <>
                                    <DspRow className={'flex-h'}>
                                        <Bubble/>
                                        <DspLabel className={'flex-main'}>Max Ids Volume</DspLabel>
                                        <div><VolumeIdsForSegments segments={dspItem.externalSegments}/></div>
                                    </DspRow>
                                    {!segmentParamsLoading &&
                                        <DspRow className={'flex-h'}>
                                            <Bubble color={'#FCA657'}/>
                                            <DspLabel className={'flex-main'}>Estimated CPM</DspLabel>
                                            <div>
                                                <DisplayCpmForSegments segments={dspItem.externalSegments}
                                                                       currency={currency}
                                                                       holdCoCpmMultiplier={segmentParamsData?.holdCoCpmMultiplier ?? 1}
                                                                       agencyAdditionalCpm={segmentParamsData?.agencyAdditionalCpm ?? 0}
                                                                       currencyRate={segmentParamsData?.currencyRate ?? 1}/>
                                            </div>
                                        </DspRow>
                                    }
                                </>}
                            </Box></Collapse>;
                    })}
                </div>;
            })}
        </ProviderContentContainer>
    </ProviderStatusContainer>;
};

const DisplayCpmForSegments = (props: {
    segments: IExternalSegment[] | undefined,
    currency: string,
    holdCoCpmMultiplier: number,
    agencyAdditionalCpm: number,
    currencyRate: number
}) => {
    const {segments, currency, holdCoCpmMultiplier, agencyAdditionalCpm, currencyRate} = props
    const {cpmMin, cpmMax} = getCPMFromSegmentsList(segments)
    const finalCpmMin = calculateCpmForSegment(cpmMin ?? 0, holdCoCpmMultiplier, agencyAdditionalCpm, currencyRate)
    const finalCpmMax = calculateCpmForSegment(cpmMax ?? 0, holdCoCpmMultiplier, agencyAdditionalCpm, currencyRate)
    return <>{getCPMToDisplay(finalCpmMin, finalCpmMax, currency)}</>
}

const VolumeIdsForSegments = (props: { segments: IExternalSegment[] | undefined }) => {
    const idCount = getTotalIdsForSegmentsList(props.segments)
    const total = getTotalFromIdsCountList(idCount)
    return <>{profilesFormatter.format(total)}</>
}

const DspLoader = styled(Box)((props: { loading: boolean }) => ({
    display: props.loading ? 'flex' : 'none',
    zIndex: 900,
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    backgroundColor: 'rgba(255,255,255,0.4)',
    // backdropFilter : 'blur(1px)',
    alignItems: 'center',
    justifyContent: 'center',
}));

const DspHeader = styled(Box)((props: { open?: boolean, theme: Theme, width?: string, position?: {} }) => ({
    cursor: 'pointer',
    position: 'static',
    marginTop: 0,
    top: 0,
    height: '50px',
    fontSize: '12px',
    display: 'flex',
    gap: '0.5rem',
    fontWeight: 'bold',
    // backgroundColor: props.theme.palette.background.default,
    backgroundColor: '#E4EAF0',
    overflow: 'hidden',
    alignItems: 'center',
    padding: '0 1rem',
    borderBottom: '1px solid white'
}));
const DspRow = styled(Box)((props: { open?: boolean, theme: Theme, width?: string, position?: {} }) => ({
    display: 'flex',
    gap: '0.5rem',
    backgroundColor: props.theme.palette.background.default,
    overflow: 'hidden',
    padding: '0.35rem 1rem',
    alignItems: 'center',
    // borderBottom   : '1px solid red',
}));
const Bubble = styled(Box)((props: { open?: boolean, theme: Theme, color?: string }) => ({
    backgroundColor: props.color ?? '#4572F9',
    width: '3px', // #FCA657  // #4572F9 // opacity : 0.8
    height: '9px',
    opacity: '0.8'
}));

const DspLabel = styled(Box)((props: { open?: boolean, theme: Theme, width?: string, position?: {} }) => ({
    color: '#7587A3'
}));
const ProviderStatusContainer = styled(Box)((props: {
    open?: boolean,
    theme: Theme,
    width?: string,
    position?: {}
}) => ({
    height: '100%',
    fontSize: '12px',
    overflow: 'hidden',
}));
const ProviderContentContainer = styled(Box)((props: {
    open?: boolean,
    theme: Theme,
    width?: string,
    position?: {}
}) => ({
    fontSize: '12px',
    overflow: 'auto',
}));
const Row = styled(Box)((props: { theme: Theme }) => ({
    display: 'flex',
    alignItems: 'center'
}));
const Title = styled(Box)((props: { theme: Theme }) => ({
    color: 'white',
    backgroundColor: '#8691A2',
}));
import React, {useEffect, useState} from "react";
import {useAuthActions, useAuthState} from "../../redux/slices/auth.slices";
import {useAppToast} from "../../components/live/AppToast";
import {apiService} from "../../application/entities/api/apiService";
import {objectApi} from "../../application/entities/dataApi";
import {Button, CircularProgress, Collapse, TextField, Typography} from "@mui/material";
import Box from "@mui/material/Box";
import {ReactHookFormItem} from "../../application/entities/dataTypes/forms";
import {useForm} from "react-hook-form";
import {useTranslation} from "react-i18next";
import {InputWrapper} from "../../components/features/Authentication/authentication.styled";
import {InputLabel} from "../../components/form/Input";
import {useUserQuery} from "../../components/hooks/data/useUserQuery";

export const MfaSetup = (props: { userid: number }) => {


    const [mfaSetupAuthorized, setMfaSetupAuthorized] = useState<boolean>(false);
    const [loadingState, setLoadingState] = React.useState<boolean>(false);
    const [showSecretCode, setShowSecretCode] = React.useState<boolean>(false);

    const {refetch} = useUserQuery({userId: props.userid});

    const {initializeMfaSetup, verifyMfaSetup} = useAuthActions();
    const appToast = useAppToast();
    const {t} = useTranslation();

    const {
        mfaQRCode,
        mfaSetupSecret,
        user
    } = useAuthState();
    const searchLoggedInUser = () => {
        return apiService
            .entity('companyUsers')
            .find('loggedInUser')
            .fetch();
    };
    const userApi = new objectApi.companyUsers();

    useEffect(() => {
        searchLoggedInUser().then(res => {
            if (res.data.id === props.userid) {
                setMfaSetupAuthorized(true)
            }
        })

    }, [props.userid])

    const handleMfaSetup = () => {
        initializeMfaSetup()
    }


    const totpField: ReactHookFormItem = {
        name: 'totp',
        label: t('auth.mfa.totpLabel'),
        type: 'text',
        options: {
            minLength: {
                value: 6,
                message: t('auth.mfa.totpError')
            }
        }
    }


    const {
        handleSubmit,
        register,
        formState: {errors},
    } = useForm({mode: 'all'});


    const onSubmit = (values: any) => {
        const code = values.totp as string
        verifyMfaSetup({code}).then(res => {
            userApi.update(user!.id!, {isMfaSetupDone: true}).then(res => {
                refetch()
                appToast.success({
                    message: 'Multi-factor Authentication enabled',
                });

            })
        }).catch(e => {
            appToast.error({
                message: e?.message ?? 'An error occurred',
            });
        });
    }


    if (!mfaSetupAuthorized) return <></>

    return <>
        <Box sx={{
            ml: 3,
            p: 3,
            borderRadius: '20px',
            width: "652px",
            backgroundColor: '#fff',
            border: '1px solid #ececec'
        }}>
            <Typography variant="h4" sx={{fontSize: '18px', fontWeight: 'bold', color: "#7587a3"}} mb={2}>Multi-factor
                Authentication</Typography>
            {!mfaQRCode && <>
                <Typography variant="body1" sx={{fontSize: '14px', color: "#7587a3"}} mb={2}>
                    Click the button below to enable Multi-factor Authentication.
                </Typography>
                <Button variant="contained" onClick={() => {
                    handleMfaSetup()
                }}>Enable Multi-factor Authentication</Button>
            </>
            }
            {mfaQRCode && <>
                <Box mb={4}
                     sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'}}>
                    <img src={mfaQRCode} alt="QR Code MFA"/>
                    <Box mb={3} sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        flexDirection: 'column',
                        gap: 1
                    }}>

                        <p>Scan the QR code with your Authenticator app </p>

                        <Button onClick={() => setShowSecretCode(!showSecretCode)}>Can't use QR Code?</Button>
                        <Collapse in={showSecretCode}>
                            <Box sx={{border: '1px solid #ececec', borderRadius: '7px', padding: '0.5rem'}}>
                                <Typography variant="body1" sx={{fontSize: '12px', color: "#7587a3"}} mb={1}>
                                    You can use this secret code to setup your Multi-Factor Authentication App
                                </Typography>
                                {mfaSetupSecret && <Typography variant="body1" sx={{
                                    fontSize: '12px',
                                    color: "#7587a3",
                                    wordBreak: 'break-word',
                                    whiteSpace: 'normal',
                                }}>{mfaSetupSecret}</Typography>}

                            </Box>
                        </Collapse>

                    </Box>

                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputWrapper key={'totp'}>
                            <InputLabel>Enter the code from your authenticator app</InputLabel>
                            <TextField
                                {...register(totpField.name, totpField.options)}
                                type={totpField.type}
                                name={totpField.name}
                                error={errors[totpField.name]}
                                helperText={errors?.totpField?.message}
                                style={{width: 224}}
                            />
                        </InputWrapper>


                        <br/>
                        <Button
                            id={'cyLoginButton'}
                            data-cy="login-button"
                            variant="contained"
                            type={'submit'}
                            startIcon={loadingState ?
                                <CircularProgress style={{color: 'white'}} size={24}/> : undefined}
                            disabled={loadingState}
                        >
                            Activate Multi-factor Authentication
                        </Button>


                    </form>

                </Box>
            </>}
        </Box>
    </>
}
import {Box, Button, Fade, Grid, styled, Typography} from "@mui/material";
import {objectApi} from "application/entities/dataApi";
import {PageId} from "application/pages/pages.config";
import {pageUtils} from "application/pages/pages.utils";
import {getUnselectedDsps} from "application/utils/dsp.utils";
import {useAllDspChannels, useSelectedDspChannels} from "components/hooks/data/useDspChannelsQuery";
import {MainFrame} from "components/templates/MainFrame";
import {useAccountTracker} from "pages/Agencies/useAccountTracker";
import {useTranslation} from "react-i18next";
import {useQuery} from "react-query";
import {useNavigate, useParams} from "react-router";
import {useFeatureFlag} from "stores/featureFlags.store";
import {ChannelDetails} from "./components/ChannelDetails";

export const ChannelsSetup = () => {
    let {accountId} = useParams<{ accountId: string }>() as { accountId: string };
    useAccountTracker(+accountId);
    const {t} = useTranslation();
    const accountsApi = new objectApi.accounts();
    const navigate = useNavigate();
    const featureFlags = useFeatureFlag((st) => st.featureFlags);

    const parentAccountId = useQuery(
        ["parentAccountId", +accountId],
        () => {
            return accountsApi.byId(+accountId);
        },
        {enabled: Boolean(accountId)}
    );

    const allDsps = useAllDspChannels(+accountId);
    const dspChannelsSelected = useSelectedDspChannels(+accountId);
    if (featureFlags?.dsp_facebook !== true) {
        dspChannelsSelected.data = dspChannelsSelected.data?.filter((x) => x.dspChannel.code !== "facebook");
    }
    const unselectedDsps = getUnselectedDsps(allDsps.data, dspChannelsSelected.data);
    const loading = parentAccountId.isLoading || allDsps.isLoading || dspChannelsSelected.isLoading;

    return (
        <>
            <MainFrame
                frameId={PageId.channelsSetup}
                checkPageAccess={true}
                grantWith={{accountId: parentAccountId?.data?.parentCompanyId}}
                loading={loading}
                breadCrumbProps={{params: {companyId: parentAccountId?.data?.parentCompanyId, brandId: accountId}}}
            >
                {!loading && (
                    <ChannelsWrap
                        data-cy="channels-list"
                        data-test-value-available={unselectedDsps.length}
                        data-test-value-selected={dspChannelsSelected?.data?.length}
                    >
                        <div style={{maxWidth: "1280px", margin: "auto"}}>
                            <Fade in={true} timeout={1000} unmountOnExit>
                                <Grid container spacing={5} direction="row" alignItems="center">
                                    {dspChannelsSelected?.data
                                        ?.sort((a, b) => (a.dspChannel.name > b.dspChannel.name ? 1 : b.dspChannel.name > a.dspChannel.name ? -1 : 0))
                                        .map((dsp) => {
                                            return (
                                                <Grid item key={dsp.id} xs={12} md={6} lg={4}
                                                      sx={{textAlign: "center"}}>
                                                    <ChannelDetails key={dsp.id} channel={dsp} accountId={+accountId}/>
                                                </Grid>
                                            );
                                        })}
                                </Grid>
                            </Fade>
                            {dspChannelsSelected?.data?.length === 0 && (
                                <Box mt={4}>
                                    <Typography variant="h4" textAlign="center">
                                        You don't have any channels configured yet. You need at least one channel to
                                        create an audience.
                                    </Typography>
                                </Box>
                            )}

                            <Box mt={4} textAlign="center">
                                <Button
                                    variant={"contained"}
                                    disabled={unselectedDsps.length === 0}
                                    data-cy={"new-channel-btn"}
                                    onClick={() => navigate(pageUtils.getPagePathById(PageId.channelsSetupAdd, {accountId: +accountId}))}
                                >
                                    {unselectedDsps.length === 0 ? t`dspChannelParameters.noMoreChannels` : t`dspChannelParameters.addChannel`}
                                </Button>
                            </Box>
                        </div>
                    </ChannelsWrap>
                )}
            </MainFrame>
        </>
    );
};

export const ChannelsWrap = styled("div")(() => ({
    padding: "2rem",
    height: "100%",
    overflow: "auto",
}));

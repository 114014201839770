import {apiService} from "application/entities/api/apiService";
import {objectApi} from "application/entities/dataApi";
import {IAudienceFeatureDto} from "application/entities/dataTypes/audienceFeature";
import {IAudiences} from "application/entities/dataTypes/audiences";
import {FeatureTypesDto} from "application/entities/dataTypes/featureTypes";
import {IFeaturesDto} from "application/entities/dataTypes/features";
import {ISubFeaturesDto} from "application/entities/dataTypes/subFeatures";
import {ITags} from "application/entities/dataTypes/tags";
import {dataUtils} from "application/utils/dataState.utils";
import axios, {CancelTokenSource} from "axios";
import {appHistoryInstance} from "components/hooks/useAppHistory";
import {mountStoreDevtool} from "simple-zustand-devtools";
import {create} from "zustand";
import {jsonDecodeArray, jsonifyArray, orderArrayStringSelected} from "../audienceBuilderUtils";
import {
    EvFeature,
    EvFeatureCodeType,
    EvFeatureGroup,
    FeatureTypeLinkedIdConfType,
    IFiltersValues,
    TypeAudienceTemplate,
} from "../types/audienceBuilderTypes";

const featureGroups = {
    basics: {
        id: 1,
        name: "Data Sources",
        code: "BASICS",
        description: "basicsDescription",
    },
    strategy: {
        id: 2,
        name: "Strategy",
        code: "STRATEGY",
        description: "strategyDescription",
    },
    demographics: {
        id: 3,
        name: "Demographics",
        code: "DEMOGRAPHICS",
        description: "demographicsDescription",
    },
    filters: {
        id: 4,
        name: "Filters",
        code: "FILTERS",
        description: "filtersDescription",
    },
    travel: {
        id: 5,
        name: "Travel",
        code: "TRAVEL",
        description: "travelDescription",
    },
    interest: {
        id: 6,
        name: "Interest",
        code: "INTEREST",
        description: "interestDescription",
    },
    carOwned: {
        id: 7,
        name: "Car Ownership",
        code: "CAROWNED",
        description: "CarOwnedDescription",
    },
    carInterest: {
        id: 8,
        name: "Auto Shoppers",
        code: "CARINTEREST",
        description: "CarInterestDescription",
    },
    myData: {
        id: 9,
        name: "My Data",
        code: "MYDATA",
        description: "MyDataDescription",
    },
    store: {
        id: 10,
        name: "Stores",
        code: "STORES",
        description: "StoreDescription",
    },
    product: {
        id: 11,
        name: "Products",
        code: "PRODUCTS",
        description: "ProductDescription",
    },
};

type TAudienceBuilderStore = {
    audienceTemplate: TypeAudienceTemplate[];
    filtersValues: IFiltersValues[];
    treeExpandedList: {
        featureCode: string;
        expandedPath: string[];
    }[];
    getFeatureByCode: (code: string) => { subFeature?: ISubFeaturesDto; feature: IFeaturesDto };

    audience: IAudiences;
    isInit: boolean;
    initMessage: string;
    EvFeatures: IFeaturesDto[];
    EvFeatureTypes: FeatureTypesDto[];
    loading: boolean;
    historyLoading: boolean;
    activeFeatureGroupCode: EvFeatureGroup["code"];
    hoverSelectId: string | null; //to highlight interest selection box
    sizeAndPrice: Partial<IAudiences>;
    loadingSizeAndPrice: boolean;
    loadingSizeAndPriceError: boolean;
    cancelTokens: {
        updateAudienceSizeAndPrice: CancelTokenSource | undefined;
        createUpdateAudienceFeature: CancelTokenSource | undefined;
        insertSubFeature: CancelTokenSource | undefined;
    };
    actions: {
        init: (audienceId: number, previewMode?: boolean) => void;
        reset: () => void;
        updateAudienceName: (name: string) => Promise<any>;
        updateAudience: (args: Partial<IAudiences>) => Promise<IAudiences>;
        restoreAudience: (args: IAudiences) => Promise<IAudiences | void>;
        updateAudienceTags: (featureValues: ITags[]) => any;
        setActiveFeatureGroupCode: (code: EvFeatureGroup["code"]) => void;
        insertFeatureValues: (feature: IFeaturesDto, featureValues: any) => Promise<any>;
        insertSubFeatureValues: (subFeature: ISubFeaturesDto, featureValues: any) => Promise<any>;
        addInterestSelect: (linkTo: number, notOperator?: boolean) => void;
        removeInterestSelect: (selectId: number | null) => void;
        createAndOperatorFeature: (featureId: number, featureValues: any[], notOperator?: boolean) => Promise<any>;
        setHoverSelectId: (id: string | null) => void;
        updateAndOperatorFeature: (audienceFeatureId: number, featureValues: any[], notOperator?: boolean) => Promise<any>;
        updateAudienceSizeAndPrice: () => Promise<any>;
        updateFiltersValues: (feature: IFeaturesDto | ISubFeaturesDto, featureValues: any) => void;
        deleteFeature: (featureCode: string, doNotDeleteParent?: boolean) => Promise<any>;
        deleteAudienceFeature: (audienceFeature: IAudienceFeatureDto) => Promise<any>;
        dropInExistingZone: (originAudienceFeatureId: number, targetAudienceFeatureId: number, featureValues: string[]) => Promise<any | void>;
        toggleTreeItem: (featureCode: string, path: string | string[]) => void;
    };
};

appHistoryInstance.isListening = true;

export const useAudienceBuilderStore = create<TAudienceBuilderStore>((set, get) => ({
    audienceTemplate: [] as TypeAudienceTemplate[],
    filtersValues: [] as IFiltersValues[],
    treeExpandedList: [],
    getFeatureByCode: (code) => {
        const templateFeatures = get().audienceTemplate.flatMap((template) => template.features) as IFeaturesDto[];
        let returnFeature: { subFeature?: ISubFeaturesDto; feature: IFeaturesDto } = {
            feature: {} as IFeaturesDto,
        };
        templateFeatures.forEach((feature) => {
            if (feature.code === code) {
                returnFeature.feature = feature as IFeaturesDto;
                if (feature.subFeatures.length > 0) {
                    returnFeature.feature = feature as IFeaturesDto;
                }
            } else {
                feature.subFeatures.forEach((subFeature) => {
                    if (subFeature.code === code) {
                        returnFeature = {
                            feature: feature as IFeaturesDto,
                            subFeature: subFeature as ISubFeaturesDto,
                        };
                    }
                });
            }
        });
        return returnFeature;
    },

    audience: {} as IAudiences,
    EvFeatures: [] as IFeaturesDto[],
    EvFeatureTypes: [] as FeatureTypesDto[],
    isInit: false as boolean,
    initMessage: "Getting datas available",
    historyLoading: false as boolean,
    activeFeatureGroupCode: "BASIC",
    loading: true as boolean,
    hoverSelectId: null as string | null,
    sizeAndPrice: {} as Partial<IAudiences>,
    loadingSizeAndPrice: false as boolean,
    loadingSizeAndPriceError: false as boolean,
    cancelTokens: {
        updateAudienceSizeAndPrice: undefined as CancelTokenSource | undefined,
        createUpdateAudienceFeature: undefined as CancelTokenSource | undefined,
        insertSubFeature: undefined as CancelTokenSource | undefined,
    },
    actions: {
        init: (audienceId, previewMode): void => {
            const audienceApi = new objectApi.audiences();
            const featuresApi = new objectApi.features();
            const featureTypesApi = new objectApi.featureTypes();

            Promise.all([
                audienceApi.byId(audienceId),
                featuresApi.get({output: {part: "EMBEDDED"}}),
                featureTypesApi.get({output: {part: "EMBEDDED"}}),
            ]).then((res) => {
                const audienceTypeId = res[0].audienceType?.id ?? 1;
                const audience = res[0] as IAudiences;
                let evFeatures = res[1] as IFeaturesDto[];

                //transform EvFeature values for array string to have object instead of string
                evFeatures = evFeatures?.map((feature) => {
                    if (feature.featureTypeCode === "arrayString") {
                        const newFeaturesValues = feature.featureValues?.map((featureValues) => {
                            return JSON.parse(featureValues ?? "[]");
                        });
                        feature.featureValues = newFeaturesValues;
                    }
                    return feature;
                });
                set({
                    audience: audience,
                    EvFeatures: evFeatures,
                    EvFeatureTypes: res[2],
                    initMessage: "Creating filters",
                    audienceTemplate: getTemplateByAudienceType(audienceTypeId, res[1]),
                });
                appHistoryInstance.initialize(res[0]);
                buildAudienceDatas(get().audienceTemplate, get().audience.account.id).then((res) => {
                    set({
                        filtersValues: res,
                    });
                    const filtersValuesUpdated = updateFiltersValuesWithAudienceFeatures(audience?.audienceFeatures, get().filtersValues, evFeatures);

                    set({
                        isInit: true,
                        loading: false,
                        filtersValues: filtersValuesUpdated,
                    });
                    if (!previewMode) {
                        get().actions.updateAudienceSizeAndPrice();
                    }
                });
            });
        },
        reset: (): void => {
            set({
                isInit: false,
                audience: {} as IAudiences,
                loading: true,
                filtersValues: [] as IFiltersValues[],
                treeExpandedList: [],
            });
        },
        updateAudienceName: async (name) => {
            const audienceId: number = get().audience.id;
            const audienceApi = new objectApi.audiences();
            set((state) => ({audience: {...state.audience, name}}));
            return audienceApi.update(audienceId, {name}).then((newAudience) => {
                // history point
                appHistoryInstance.pushStep(newAudience);
                // update state with real data
                if (newAudience) set((state) => ({audience: {...state.audience, ...newAudience}}));
                return newAudience;
            });
        },

        updateAudience: async (aud) => {
            if (get().audience.audienceStatus === "ACTIVATED") return new Promise((resolve, reject) => reject("Audience is active and cannot be modified"));

            // history action point
            appHistoryInstance.pushActionStep(() => {
                get().actions.updateAudience(aud);
            });

            const audienceId: number = get().audience.id;
            const audienceApi = new objectApi.audiences();
            // pre-update state
            set((state) => ({audience: {...state.audience, ...aud}}));

            return audienceApi.update(audienceId, aud).then((newAudience) => {
                // history point
                appHistoryInstance.pushStep(newAudience);
                // update state with real data
                if (newAudience) set((state) => ({audience: {...state.audience, ...newAudience}}));
                return newAudience;
            });
        },

        restoreAudience: async (audience) => {
            const currentAudience = get().audience;
            const audienceId = currentAudience.id;
            const audienceApi = new objectApi.audiences();

            if (get().historyLoading) return;
            set({historyLoading: true});
            return audienceApi
                .undoRedo(audienceId, audience)
                .then((res) => {
                    if (res?.data) {
                        set({audience: res.data});
                        updateFiltersValuesWithAudienceFeatures(get().audience.audienceFeatures, get().filtersValues, get().EvFeatures);
                        get().actions.updateAudienceSizeAndPrice();
                    }
                    return res;
                })
                .catch((e) => e)
                .finally(() => {
                    set({historyLoading: false});
                });
        },

        updateAudienceTags: async (featureValues: ITags[]) => {
            const audienceApi = new objectApi.audiences();
            set((st) => {
                return {...st, audience: {...st.audience, tags: featureValues}};
            });
            // history point
            return audienceApi.updateTags(get().audience.id, featureValues).then((res) => {
                appHistoryInstance.pushStep({...get().audience, tags: featureValues});
                return res;
            });
        },

        setActiveFeatureGroupCode: (code) => {
            if (get().activeFeatureGroupCode === code) return;
            set({activeFeatureGroupCode: code});
        },

        updateFiltersValues: (feature, featureValues) => {
            const filtersValues = [...get().filtersValues];

            const featureIdx = filtersValues.findIndex((f) => f.code === feature.code);

            //delete income filter value if location is not US or AU
            if (feature.code === "LOCATION") {
                const locationFilterValues = jsonDecodeArray(featureValues);
                const countriesCodesWithIncome = ["US", "AU"];

                const isIncomeValueAvailable = Boolean(
                    locationFilterValues?.filter((selection) => {
                        return countriesCodesWithIncome.includes(selection.id);
                    }).length
                );
                if (!isIncomeValueAvailable) {
                    get().actions.deleteFeature("INCOME");
                }
            }
            if (featureIdx > -1) {
                if (feature.code === "INTEREST") {
                    //use only for the first interest select, the other use updateAndOperatorFeature
                    //first selection
                    if (featureValues.length === 0) {
                        filtersValues[featureIdx].selected = [];
                    } else {
                        //first selection on the first select
                        if (filtersValues[featureIdx].selected.length === 0) {
                            filtersValues[featureIdx].selected[0] = {

                                selected: jsonDecodeArray(featureValues),
                                linkTo: 0,
                                notOperator: false
                            };
                        } else {
                            filtersValues[featureIdx].selected[0].selected = jsonDecodeArray(featureValues);
                        }
                    }
                } else if (["percentage"].includes(feature.featureType.code)) {
                    const value = Array.isArray(featureValues) ? [] : [featureValues.toString()];
                    filtersValues[featureIdx].selected = value;
                } else if (feature.code === 'AGE_CHILDREN') {
                    filtersValues[featureIdx].selected = featureValues.length === 0 ? [] : featureValues;
                } else if (feature.code === "AGE" && featureValues.length === 1) {
                    //for age slider
                    filtersValues[featureIdx].selected = featureValues;
                } else if (["arrayString"].includes(feature.featureType.code)) {
                    featureValues = orderArrayStringSelected(filtersValues[featureIdx].selectable, featureValues);
                    filtersValues[featureIdx].selected = featureValues;
                } else if (["checklist"].includes(feature.featureType.code)) {
                    filtersValues[featureIdx].selected = featureValues ?? [featureValues];
                } else if (["slider"].includes(feature.featureType.code)) {
                    if (featureValues) filtersValues[featureIdx].selected = featureValues.length === 0 ? [] : [featureValues];
                } else if (["templateUrlList"].includes(feature.featureType.code)) {
                    if (featureValues) filtersValues[featureIdx].selected = featureValues;
                } else if (["date"].includes(feature.featureType.code) && feature.code !== "CPG_PURCHASE_RECENCY") {
                    if (featureValues) filtersValues[featureIdx].selected = featureValues;
                } else if (["date"].includes(feature.featureType.code) && feature.code === "CPG_PURCHASE_RECENCY") {
                    if (featureValues) filtersValues[featureIdx].selected = featureValues.length ? [featureValues] : [];
                } else {
                    filtersValues[featureIdx].selected = jsonDecodeArray(featureValues);
                }
                const updatedFiltersValues = [...filtersValues] as IFiltersValues[];
                set({filtersValues: updatedFiltersValues});
            }
        },

        toggleTreeItem(featureCode, path) {
            const expandedList = get().treeExpandedList.find((item) => item.featureCode === featureCode);
            if (expandedList) {
                if (Array.isArray(path)) {
                    //coming from select: array is sent
                    let newState = get().treeExpandedList.map((item) => {
                        if (item.featureCode === featureCode) {
                            item.expandedPath = path;
                        }
                        return item;
                    });
                    set((st) => {
                        return {...st, treeExpandedList: newState};
                    });
                } else {
                    const pathIdx = expandedList.expandedPath.findIndex((item) => item === path);
                    if (pathIdx > -1) {
                        let newState = get().treeExpandedList.map((item) => {
                            if (item.featureCode === featureCode) {
                                item.expandedPath.splice(pathIdx, 1);
                            }
                            return item;
                        });
                        set((state) => {
                            return {...state, treeExpandedList: newState};
                        });
                    } else {
                        let newState = get().treeExpandedList.map((item) => {
                            if (item.featureCode === featureCode) {
                                item.expandedPath.push(path);
                            }
                            return item;
                        });
                        set((state) => {
                            return {...state, treeExpandedList: newState};
                        });
                    }
                }
            } else {
                if (Array.isArray(path)) {
                    set((state) => {
                        return {
                            ...state,
                            treeExpandedList: [
                                ...state.treeExpandedList,
                                {
                                    featureCode: featureCode,
                                    expandedPath: path,
                                },
                            ],
                        };
                    });
                } else {
                    set((state) => {
                        return {
                            ...state,
                            treeExpandedList: [
                                ...state.treeExpandedList,
                                {
                                    featureCode: featureCode,
                                    expandedPath: [path],
                                },
                            ],
                        };
                    });
                }
            }
        },

        insertFeatureValues: async (feature, featureValues) => {

            get().actions.updateFiltersValues(feature, featureValues);


            if (["INCOME", "AUTO_CURRENT_AGE", "AUTO_INMARKET_VALUE"].includes(feature.code)) {
                let incomeFeatureValues: { min?: number; max?: number } = {...featureValues};
                if (incomeFeatureValues.min === 0) {
                    delete incomeFeatureValues.min;
                }
                const incomeFilter = get().filtersValues.find((f) => f.code === feature.code);
                if (incomeFeatureValues.max === incomeFilter?.selectable[0].max) {
                    delete incomeFeatureValues.max;
                }
                featureValues = JSON.stringify(incomeFeatureValues);
            }

            if (["INCLUSION_LIST"].includes(feature.code)) {
                featureValues = jsonifyArray(featureValues);
            }

            if (get().audience.audienceStatus === "ACTIVATED") return new Promise((resolve, reject) => reject("Audience is active and cannot be modified"));
            const castedValues = castFeatureValues(feature, featureValues);
            const audienceFeatureList: IAudienceFeatureDto[] | [] = get().audience.audienceFeatures;
            if (castedValues?.length === 0 || castedValues[0] === 0) {
                const audienceFeatureExists = dataUtils.getObjectItemById<IAudienceFeatureDto>(audienceFeatureList, feature.id, "featureId")[0];
                return get().actions.deleteAudienceFeature(audienceFeatureExists);
            }

            const audienceApi = new objectApi.audiences();
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            if (get().cancelTokens.createUpdateAudienceFeature) {
                get().cancelTokens!.createUpdateAudienceFeature!.cancel("Operation canceled by the user.");
            }
            set({cancelTokens: {...get().cancelTokens, createUpdateAudienceFeature: source}});
            return audienceApi
                .createUpdateAudienceFeature(get().audience.id, feature.id, {featureValues: castFeatureValues(feature, featureValues)}, source.token)
                .then((res) => {
                    if (res?.data?.id) {
                        if (feature.code === "INTEREST") {
                            //we need to update filter cause interest needs feature id from api
                            updateFiltersValuesWithAudienceFeatures(res.data.audienceFeatures, get().filtersValues, get().EvFeatures);
                        }
                        get().actions.updateAudienceSizeAndPrice();
                        // history point
                        appHistoryInstance.pushStep(res.data as IAudiences);
                        set((state) => {
                            return {...state, audience: res.data as IAudiences};
                        });
                    }
                    return res;
                });
        },

        insertSubFeatureValues: async (subFeature, featureValues) => {
            if (get().audience.audienceStatus === "ACTIVATED") return new Promise((resolve, reject) => reject("Audience is active and cannot be modified"));
            const castedValues = castFeatureValues(subFeature.featureType.code, featureValues);

            //update filtersValues
            const filtersValues = [...get().filtersValues];
            const featureIdx = filtersValues.findIndex((f) => f.code === subFeature.code);
            if (featureIdx > -1) {
                //linkedId and checklist has different behavior
                if (subFeature.featureType.code === "linkedId") {
                    filtersValues[featureIdx].selected = jsonDecodeArray(featureValues);
                } else {
                    filtersValues[featureIdx].selected = [jsonDecodeArray([featureValues])[0]];
                }
                //const newValue = filtersValues
                set({filtersValues: filtersValues});
            }

            //update state before request
            const audienceFeatureIdx = get().audience.audienceFeatures.findIndex(
                (audienceFeature) => audienceFeature?.subFeature?.code === subFeature.code
            );
            if (audienceFeatureIdx > -1) {
                let actualAudienceFeature = get().audience.audienceFeatures;
                actualAudienceFeature[audienceFeatureIdx].featureValues = featureValues.constructor === Array ? featureValues : [featureValues];
                set({audience: {...get().audience, audienceFeatures: actualAudienceFeature}});
            }

            const audienceApi = new objectApi.audiences();
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            if (get().cancelTokens.insertSubFeature) {
                get().cancelTokens!.insertSubFeature!.cancel("Operation canceled by the user.");
            }
            set({cancelTokens: {...get().cancelTokens, insertSubFeature: source}});
            return audienceApi
                .createUpdateAudienceSubFeature(get().audience.id, subFeature.id, {featureValues: castedValues}, source.token)
                .then((res) => {
                    // add featureValues to state tmpAudienceFeatures
                    if (res?.data?.id) {
                        get().actions.updateAudienceSizeAndPrice();
                        // history point
                        appHistoryInstance.pushStep(res.data);
                        set((state) => {
                            return {...state, audience: res.data as IAudiences};
                        });
                    }

                    return res;
                });
        },

        addInterestSelect: (linkTo: number, notOperator?: boolean): void => {
            const interestSelects = [...get().filtersValues.filter((filter) => filter.code === "INTEREST")][0];
            interestSelects.selected.push({
                selected: [],
                linkTo: linkTo,
                notOperator: notOperator === undefined ? false : notOperator
            });
            const filterValueInterestIdx = [...get().filtersValues].findIndex((filter) => filter.code === "INTEREST");
            let newFiltersValues = [...get().filtersValues];
            newFiltersValues[filterValueInterestIdx] = interestSelects;
            set({filtersValues: newFiltersValues});
        },

        removeInterestSelect: (selectId: number | null) => {
            const selectors = [...get().filtersValues.filter((filter) => filter.code === "INTEREST")[0].selected];
            const updatedSelectors = selectId !== null ? selectors.filter((selector) => selector.featureId !== selectId) : selectors.filter((selector) => selector?.featureId);
            //update linkTo
            let featureId = updatedSelectors[0].featureId;
            updatedSelectors.forEach((selector, idx) => {
                if (selector.linkTo !== 0) {
                    updatedSelectors[idx].linkTo = featureId;
                }
                featureId = selector.featureId;
            });
            let newFiltersValues = [...get().filtersValues];
            newFiltersValues.filter((filter) => filter.code === "INTEREST")[0].selected = updatedSelectors;

            set({filtersValues: newFiltersValues});
            if (selectId !== null) {
                get().actions.updateAndOperatorFeature(selectId, []);

            }

        },

        updateAndOperatorFeature: (audienceFeatureId: number, featureValues: any, notOperator?: boolean) => {
            //custom update filter cause interest is a special case (multiple selects)
            const filtersValues = [...get().filtersValues];
            filtersValues.forEach((filterValue) => {
                if (filterValue.code === "INTEREST") {
                    filterValue.selected.forEach((selected) => {
                        if (selected.featureId === audienceFeatureId) {
                            if (featureValues.length === 0) {
                                get().actions.removeInterestSelect(audienceFeatureId);
                            } else {
                                selected.selected = jsonDecodeArray(featureValues);
                            }
                        }
                    });
                }
            });
            set((state) => {
                return {...state, filtersValues: filtersValues};
            });
            const audienceApi = new objectApi.audiences();
            return audienceApi.updateAndOperatorFeature(get().audience.id, audienceFeatureId, featureValues, notOperator).then((res) => {
                if (res?.data?.id) {
                    get().actions.updateAudienceSizeAndPrice();
                    appHistoryInstance.pushStep(res.data as IAudiences);
                    set((state) => {
                        return {...state, audience: res.data as IAudiences};
                    });

                }
                return res;
            });
        },

        setHoverSelectId: (id: string | null) => {
            if (id !== get().hoverSelectId) {
                set({hoverSelectId: id ?? null});
            }
        },

        createAndOperatorFeature: (featureId: number, featureValues: any, notOperator?: boolean) => {
            //update state before request
            let linkToForFutureUpdate: any = null;
            const filtersValues = [...get().filtersValues];
            filtersValues.forEach((filterValue) => {
                if (filterValue.code === "INTEREST") {
                    filterValue.selected.forEach((selected) => {
                        if (selected.featureId === undefined) {
                            selected.selected = jsonDecodeArray(featureValues);
                            linkToForFutureUpdate = selected.linkTo;
                        }
                    });
                }
            });

            set((state) => {
                return {...state, filtersValues: filtersValues};
            });

            const audienceApi = new objectApi.audiences();
            return audienceApi.createAndOperatorFeature(get().audience.id, featureId, featureValues, notOperator).then((res) => {
                if (res?.data?.id) {
                    //update state after request for selectedFeatureId = 1 cause this was temporary
                    const filterValueUpdated = res.data.audienceFeatures.find(
                        (audienceFeature: any) => audienceFeature.linkTo === linkToForFutureUpdate.toString()
                    );
                    const filterValue = [...get().filtersValues.find((filter) => filter.code === "INTEREST")!.selected];
                    const updateSelect = filterValue.find((select: any) => select.featureId === undefined);
                    updateSelect.featureId = filterValueUpdated.id;
                    const filtersValues = [...get().filtersValues];
                    filtersValues.forEach((filterValue) => {
                        if (filterValue.code === "INTEREST") {
                            filterValue.selected.forEach((selected) => {
                                if (selected.featureId === undefined) {
                                    selected = updateSelect;
                                }
                            });
                        }
                    });
                    set((state) => {
                        return {...state, filtersValues: filtersValues};
                    });
                    get().actions.updateAudienceSizeAndPrice();
                    appHistoryInstance.pushStep(res.data as IAudiences);
                    set((state) => {
                        return {...state, audience: res.data as IAudiences};
                    });
                }
                return res;
            });
        },

        updateAudienceSizeAndPrice: () => {
            const audienceStatus = get().audience?.audienceStatus;
            if (["ACTIVATED", "ARCHIVED"].includes(audienceStatus)) {
                return new Promise((resolve, reject) => resolve("Audience is active and cannot be modified"));
            }
            set({loadingSizeAndPrice: true});
            const audienceApi = new objectApi.audiences();
            const CancelToken = axios.CancelToken;
            const source = CancelToken.source();
            if (get().cancelTokens.updateAudienceSizeAndPrice) {
                get().cancelTokens!.updateAudienceSizeAndPrice!.cancel("Operation canceled by the user.");
            }
            set({cancelTokens: {...get().cancelTokens, updateAudienceSizeAndPrice: source}});
            return audienceApi
                .updateAudienceSizeAndPrice(get().audience.id, source.token)
                .then((resA) => {
                    if (resA) {
                        set({sizeAndPrice: resA.data, loadingSizeAndPrice: false, loadingSizeAndPriceError: false});
                    }
                })
                .catch((e) => {
                    set({loadingSizeAndPrice: false, loadingSizeAndPriceError: true});
                });
        },

        deleteFeature: async (featureCode: string, doNotDeleteParent: boolean = false) => {
            //remove feature from filtersValues
            const feature = get().getFeatureByCode(featureCode);
            if (feature?.feature?.subFeatures?.length > 0) {
                const subFeatures = feature?.feature?.subFeatures;

                if (doNotDeleteParent) {
                    const featureToDelete = subFeatures.find((subFeature) => subFeature.code === featureCode) as ISubFeaturesDto;
                    get().actions.updateFiltersValues(featureToDelete, []);
                } else {
                    subFeatures.forEach((subFeature) => {
                        const featureToDelete = get().getFeatureByCode(subFeature.code).subFeature!;
                        get().actions.updateFiltersValues(featureToDelete, []);
                    });
                }
            } else {
                get().actions.updateFiltersValues(feature.feature, []);
            }

            const audienceFeaturesApi = new objectApi.audienceFeatures();
            const audienceFeatureList: IAudienceFeatureDto[] = get().audience.audienceFeatures;
            const audienceFeatureExists = dataUtils.getObjectItemById<IAudienceFeatureDto>(audienceFeatureList, featureCode, "feature.code");
            const audienceSubFeatureExists = dataUtils.getObjectItemById<IAudienceFeatureDto>(audienceFeatureList, featureCode, "subFeature.code");
            const audienceFeaturesToDelete = audienceFeatureExists.concat(audienceSubFeatureExists);

            let promises: Promise<any>[] = [];
            for (const audienceFeatures of audienceFeaturesToDelete) {
                promises.push(
                    audienceFeaturesApi.delete(audienceFeatures.id).then((res) => {
                        return res;
                    })
                );
            }

            Promise.all(promises).then((res) => {
                const audienceApi = new objectApi.audiences();
                audienceApi.byId(get().audience.id).then((resA) => {
                    get().actions.updateAudienceSizeAndPrice();
                    appHistoryInstance.pushStep(resA);
                    set((state) => {
                        return {...state, audience: resA as IAudiences};
                        //get().actions.updateInterestSelects();
                    });
                });
            });
        },

        deleteAudienceFeature: async (aud: IAudienceFeatureDto) => {
            if (get().audience.audienceStatus === "ACTIVATED") return new Promise((resolve, reject) => reject("Audience is active and cannot be modified"));
            if (!aud || !aud.id) return new Promise((resolve, reject) => resolve("Audience Feature does not exist and wont be deleted"));
            const audienceFeaturesApi = new objectApi.audienceFeatures();

            const newAudienceFeatureList = dataUtils.removeFragmentItemById(get().audience.audienceFeatures, aud.id);
            set((state) => {
                return {...state, audience: {...state.audience, audienceFeatures: newAudienceFeatureList}};
            });

            return audienceFeaturesApi.delete(aud.id).then((res) => {
                const audienceApi = new objectApi.audiences();
                audienceApi.byId(get().audience.id).then((resA) => {
                    get().actions.updateAudienceSizeAndPrice();
                    appHistoryInstance.pushStep(resA);
                });
                return res;
            });
        },

        dropInExistingZone: async (originAudienceFeatureId: number, targetAudienceFeatureId: number, featureValues: string[]) => {
            const audienceApi = new objectApi.audiences();
            return audienceApi.dropInExistingZone(get().audience.id, originAudienceFeatureId, targetAudienceFeatureId, featureValues).then((res) => {
                if (res?.data) {
                    get().actions.updateAudienceSizeAndPrice();
                    set({audience: res.data});
                    //if origin feature select is empty remove selector
                    const selectors = [...get().filtersValues.filter((filter) => filter.code === "INTEREST")[0].selected];
                    const originSelector = selectors.filter((selector) => selector.featureId === originAudienceFeatureId)[0];
                    if (originSelector.selected.length === 1) {
                        get().actions.removeInterestSelect(originAudienceFeatureId);
                    }
                    updateFiltersValuesWithAudienceFeatures(res.data.audienceFeatures, get().filtersValues, get().EvFeatures);

                    appHistoryInstance.pushStep({...res.data});
                    return res;
                }
            });
        },
    },
}));

function castFeatureValues(feature: IFeaturesDto | EvFeatureCodeType, featureValue: any) {
    let newValues: any;
    let inVal: any = featureValue;

    // @ts-ignore
    const swCode = typeof feature === "string" ? feature : feature.featureType.code;

    const FeatureCode = typeof feature === "string" ? feature : feature.code;

    switch (swCode) {
        case "arrayString":
            if (typeof feature === "object" && feature.code === "AGE") {
                // for age slider
                inVal = featureValue;
            } else if (!Array.isArray(featureValue)) inVal = featureValue === 0 ? [] : ([featureValue] as any); // as an array
            break;
        case "linkedId":
            inVal = featureValue;
            break;
        case "templateUrlList":
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            newValues = JSON.stringify(featureValue);
            break;
        case "percentage":
        default:
            inVal = [featureValue];
            break;
    }

    if (FeatureCode === 'AGE_CHILDREN') {
        inVal = featureValue.map((item: any) => JSON.stringify(item))
    }

    return inVal;
}

if (process.env.NODE_ENV === "development") {
    mountStoreDevtool("useAudienceBuilderStore", useAudienceBuilderStore);
}

const getTemplateByAudienceType = (audienceType: number, allFeatures: EvFeature[]) => {
    //1: advanced - 2: travel - 3: interest 4- automotive
    //console.table( allFeatures)
    //console.log('audienceType', audienceType)
    const interestBuilder: TypeAudienceTemplate[] = [
        {
            featureGroupCode: featureGroups.interest.code,
            featureGroupId: featureGroups.interest.id,
            featureGroupName: featureGroups.interest.name,
            features: [
                allFeatures.filter((f) => f.code === "INTEREST")[0],
                allFeatures.filter((f) => f.code === "LOCATION")[0],
                allFeatures.filter((f) => f.code === "EXCLUSION_LIST")[0],
                allFeatures.filter((f) => f.code === "PROFILERECENCYFEATURE")[0],
            ],
        },
    ];

    const travelBuilder: TypeAudienceTemplate[] = [
        {
            featureGroupCode: featureGroups.travel.code,
            featureGroupId: featureGroups.travel.id,
            featureGroupName: featureGroups.travel.name,
            features: [
                allFeatures.filter((f) => f.code === "TRAVELROUTE")[0],
                allFeatures.filter((f) => f.code === "TRAVELPERIOD")[0],
                allFeatures.filter((f) => f.code === "TRAVELRECENCYFEATURE")[0],
                allFeatures.filter((f) => f.code === "EXCLUSION_LIST")[0]
            ],
        },
    ];

    const advancedBuilder: TypeAudienceTemplate[] = [
        {
            featureGroupCode: featureGroups.basics.code,
            featureGroupId: featureGroups.basics.id,
            featureGroupName: featureGroups.basics.name,
            features: [allFeatures.filter((f) => f.code === "DATASOURCE")[0], allFeatures.filter((f) => f.code === "LOCATION")[0]],
        },
        {
            featureGroupCode: featureGroups.strategy.code,
            featureGroupId: featureGroups.strategy.id,
            featureGroupName: featureGroups.strategy.name,
            features: [
                allFeatures.filter((f) => f.code === "INTEREST")[0],
                allFeatures.filter((f) => f.code === "INTEREST_BRAND")[0],
                allFeatures.filter((f) => f.code === "INTENT")[0]],
        },
        {
            featureGroupCode: featureGroups.demographics.code,
            featureGroupId: featureGroups.demographics.id,
            featureGroupName: featureGroups.demographics.name,
            features: [
                allFeatures.filter((f) => f.code === "AGE")[0],
                allFeatures.filter((f) => f.code === "AGE_CHILDREN")[0],
                allFeatures.filter((f) => f.code === "GENDER")[0],
                allFeatures.filter((f) => f.code === "FAMILY_FILTER")[0],
                allFeatures.filter((f) => f.code === "POLITICAL_PARTY_AFFILIATION")[0],
                allFeatures.filter((f) => f.code === "POLITICAL_OUTLOOK")[0],
                allFeatures.filter((f) => f.code === "INCOME")[0],
                allFeatures.filter((f) => f.code === "HOMEOWNERSHIP")[0],
                allFeatures.filter((f) => f.code === "EDUCATION")[0],
            ],
        },
        {
            featureGroupCode: featureGroups.filters.code,
            featureGroupId: featureGroups.filters.id,
            featureGroupName: featureGroups.filters.name,
            features: [
                allFeatures.filter((f) => f.code === "EXCLUSION_LIST")[0],
                allFeatures.filter((f) => f.code === "CERTAIN")[0],
                allFeatures.filter((f) => f.code === "PROFILERECENCYFEATURE")[0],
            ],
        },
    ];

    const automotiveBuilder: TypeAudienceTemplate[] = [
        {
            featureGroupCode: featureGroups.carInterest.code,
            featureGroupId: featureGroups.carInterest.id,
            featureGroupName: featureGroups.carInterest.name,
            features: [
                allFeatures.filter((f) => f.code === "AUTO_INMARKET_NEW_USED")[0],
                allFeatures.filter((f) => f.code === "AUTO_INMARKET_VALUE")[0],
                allFeatures.filter((f) => f.code === "AUTO_INMARKET_SEGMENT")[0],
                allFeatures.filter((f) => f.code === "AUTO_INMARKET_MODEL")[0],
                allFeatures.filter((f) => f.code === "AUTO_INMARKET_ENERGY")[0],
                allFeatures.filter((f) => f.code === "AUTO_INMARKET_PURCHASE")[0],
            ],
        },
        {
            featureGroupCode: featureGroups.carOwned.code,
            featureGroupId: featureGroups.carOwned.id,
            featureGroupName: featureGroups.carOwned.name,
            features: [
                allFeatures.filter((f) => f.code === "AUTO_CURRENT_MODEL")[0],
                allFeatures.filter((f) => f.code === "AUTO_CURRENT_AGE")[0],
                allFeatures.filter((f) => f.code === "AUTO_CURRENT_LOYALTY")[0],
                allFeatures.filter((f) => f.code === "AUTO_CURRENT_PURCHASE")[0],
            ],
        },
        {
            featureGroupCode: featureGroups.demographics.code,
            featureGroupId: featureGroups.demographics.id,
            featureGroupName: featureGroups.demographics.name,
            features: [
                allFeatures.filter((f) => f.code === "AGE")[0],
                allFeatures.filter((f) => f.code === "GENDER")[0],
                allFeatures.filter((f) => f.code === "INCOME")[0],
            ],
        },
    ];

    const firstPartyDataBuilder: TypeAudienceTemplate[] = [
        {
            featureGroupCode: featureGroups.myData.code,
            featureGroupId: featureGroups.myData.id,
            featureGroupName: featureGroups.myData.name,
            features: [allFeatures.filter((f) => f.code === "INCLUSION_LIST")[0], allFeatures.filter((f) => f.code === "EXCLUSION_LIST")[0]],
        },
    ];

    const cpgBuilder: TypeAudienceTemplate[] = [
        {
            featureGroupCode: featureGroups.basics.code,
            featureGroupId: featureGroups.basics.id,
            featureGroupName: featureGroups.basics.name,
            features: [allFeatures.filter((f) => f.code === "LOCATION")[0]],
        },
        {
            featureGroupCode: featureGroups.store.code,
            featureGroupId: featureGroups.store.id,
            featureGroupName: featureGroups.store.name,
            features: [allFeatures.filter((f) => f.code === "CPG_VISITED_STORE")[0], allFeatures.filter((f) => f.code === "CPG_STORE_SIZE")[0]],
        },
        {
            featureGroupCode: featureGroups.product.code,
            featureGroupId: featureGroups.product.id,
            featureGroupName: featureGroups.product.name,
            features: [
                allFeatures.filter((f) => f.code === "CPG_BRAND")[0],
                allFeatures.filter((f) => f.code === "CPG_PURCHASED_PRODUCT_TYPE")[0],
                allFeatures.filter((f) => f.code === "CPG_PURCHASE_RECENCY")[0]
            ]
        },
        {
            featureGroupCode: featureGroups.demographics.code,
            featureGroupId: featureGroups.demographics.id,
            featureGroupName: featureGroups.demographics.name,
            features: [
                allFeatures.filter((f) => f.code === "AGE")[0],
                allFeatures.filter((f) => f.code === "GENDER")[0],
                allFeatures.filter((f) => f.code === "CPG_SPENDING_BEHAVIOR")[0],
            ],
        },
    ];

    if (audienceType === 1) return advancedBuilder;
    if (audienceType === 2) return travelBuilder;
    if (audienceType === 3) return interestBuilder;
    if (audienceType === 4) return automotiveBuilder;
    if (audienceType === 5) return firstPartyDataBuilder;
    if (audienceType === 6) return cpgBuilder;
    return advancedBuilder;
};

/**
 * Parse the template to get all linkedId features who needs a request to get features and make requests
 * @param template
 * @param audienceFeatures
 * @param accountId
 */
const buildAudienceDatas = (template: TypeAudienceTemplate[], accountId: number) => {
    const accountApi = new objectApi.accounts();
    return accountApi.getMyFeatureFlags(accountId).then((ffres: any) => {
        const featureFlags = ffres?.data?.featureFlags ?? {};

        let requestList: {
            code: string;
            groupCode: string;
            parentCode?: string;
            uri: string;
            type: string;
            name: string;
        }[] = [];
        let filtersValues: IFiltersValues[] = [];
        template.forEach((tab) => {
            tab.features.forEach((feature) => {
                // subFeatures
                if (Array.isArray(feature?.subFeatures) && feature?.subFeatures.length > 0) {
                    if (feature.featureTypeCode === "linkedId") {
                        //linkedId values are get from a request
                        Array.isArray(feature.subFeatures) &&
                        feature.subFeatures.forEach((subFeature) => {
                            const featConf: FeatureTypeLinkedIdConfType = jsonDecodeArray(subFeature.featureValues)[0];
                            const featUri = featConf.entity.replace("{accountId}", `${accountId}`);
                            requestList.push({
                                code: subFeature.code,
                                parentCode: feature.code,
                                groupCode: tab.featureGroupCode,
                                uri: featUri,
                                type: feature.featureTypeCode,
                                name: subFeature.name,
                            });
                        });
                    } else if (feature.featureTypeCode === "checklist") {
                        // checklist values are a array of object
                        Array.isArray(feature.subFeatures) &&
                        feature.subFeatures.forEach((subFeature) => {
                            filtersValues.push({
                                code: subFeature.code,
                                parentCode: feature.code,
                                groupCode: tab.featureGroupCode,
                                type: feature.featureTypeCode,
                                name: subFeature.name,
                                selectable: jsonDecodeArray(subFeature.featureValues),
                                selected: [],
                            });
                        });
                    } else {
                        Array.isArray(feature.subFeatures) &&
                        feature.subFeatures.forEach((subFeature) => {
                            filtersValues.push({
                                code: subFeature.code,
                                parentCode: feature.code,
                                groupCode: tab.featureGroupCode,
                                name: subFeature.name,
                                type: feature.featureTypeCode,
                                selectable: subFeature.featureValues,
                                selected: [],
                            });
                        });
                    }
                } else {
                    //no subFeatures
                    if (feature.featureTypeCode === "linkedId") {
                        const featConf: FeatureTypeLinkedIdConfType = jsonDecodeArray(feature.featureValues)[0];
                        const featUri = featConf.entity.replace("{accountId}", `${accountId}`);
                        requestList.push({
                            code: feature.code,
                            groupCode: tab.featureGroupCode,
                            uri: featUri,
                            type: feature.featureTypeCode,
                            name: feature.name,
                        });

                    } else if (feature.featureTypeCode === "templateUrlList") {
                        const featUri = feature.featureValues[0].replace("{accountId}", `${accountId}`);
                        requestList.push({
                            code: feature.code,
                            groupCode: tab.featureGroupCode,
                            uri: featUri,
                            type: feature.featureTypeCode,
                            name: feature.name,
                        });
                    } else if (["INCOME", "AUTO_INMARKET_VALUE", "AUTO_CURRENT_AGE", "INTEREST_BRAND"].includes(feature.code)) {
                        filtersValues.push({
                            code: feature.code,
                            groupCode: tab.featureGroupCode,
                            type: feature.featureTypeCode,
                            name: feature.name,
                            selectable: jsonDecodeArray(feature.featureValues),
                            selected: [],
                        });
                    } else if (feature.featureTypeCode === "date") {
                        console.log('feature', feature)
                        filtersValues.push({
                            code: feature.code,
                            groupCode: tab.featureGroupCode,
                            type: feature.featureTypeCode,
                            name: feature.name,
                            selectable: [],
                            selected: []

                        });
                    } else {
                        filtersValues.push({
                            code: feature.code,
                            groupCode: tab.featureGroupCode,
                            type: feature.featureTypeCode,
                            name: feature.name,
                            selectable: feature.featureValues,
                            selected: [],
                        });
                    }
                }
            });
        });

        return Promise.all(
            requestList.map((req) =>
                apiService
                    .route(req.uri, {showProjection: false})
                    .page({
                        pageSize: 800,
                        pageNumber: 0,
                    })
                    .fetch()
            )
        ).then((res) => {
            const FiltersDatas = requestList.map((reqItem, idx) => {
                const code = reqItem.code;
                let response: any = [];
                //exception for exclusion list (templateUrlList)
                // exclusion list selectable won't be used, look at the component InputTemplateUrlList
                if (code === "INCLUSION_LIST") {
                    response = res[idx]?.data?.firstPartyDatas ?? [];
                } else if (code === "EXCLUSION_LIST") {
                    response = res[idx]?.data?.firstPartyDatas ?? [];
                } else {
                    response = Object.values(res[idx]?.data?._embedded)?.[0] ?? [];
                }
                return {
                    code: code,
                    parentCode: reqItem.parentCode,
                    groupCode: reqItem.groupCode,
                    selectable: response,
                    type: reqItem.type,
                    name: reqItem.name,
                    selected: [],
                };
            });
            return [...FiltersDatas, ...filtersValues];
        });
    });
};

const updateFiltersValuesWithAudienceFeatures = (
    audienceFeatures: IAudienceFeatureDto[],
    filtersValues: IFiltersValues[],
    evFeatures: IFeaturesDto[]
) => {
    const audienceFeaturesList = [...audienceFeatures];
    const filterValues = [...filtersValues];
    audienceFeaturesList?.forEach((audienceFeature) => {
        let featureValues = audienceFeature.featureValues;
        if (["templateUrlList"].includes(audienceFeature.featureTypeCode)) {
            featureValues = audienceFeature.featureValues;
        }
        if (["INCLUSION_LIST"].includes(audienceFeature.feature.code)) {
            featureValues = jsonDecodeArray(audienceFeature.featureValues);
        }

        if (["linkedId", "checklist"].includes(audienceFeature.featureTypeCode)) {
            featureValues = jsonDecodeArray(audienceFeature.featureValues);
        }
        if (audienceFeature.subFeature && audienceFeature.featureTypeCode === "date") {
            featureValues = jsonDecodeArray(audienceFeature.featureValues);
        }

        if (audienceFeature.featureTypeCode === "date" && !audienceFeature.subFeature) {
            featureValues = audienceFeature.featureValues
        }

        //If the feature type is array string, we have to order the selected values because other values can be present in the selected array
        if (audienceFeature.featureTypeCode === "arrayString") {
            if (audienceFeature.feature.code === 'AGE_CHILDREN') {
                featureValues = audienceFeature.featureValues.map(val => JSON.parse(val));
            } else if (audienceFeature.feature.code === "AGE") {
                featureValues = audienceFeature.featureValues;
            } else {
                const filterValueArrayString = filterValues.find((filterValue) => filterValue.code === audienceFeature.feature.code);
                featureValues = orderArrayStringSelected(filterValueArrayString?.selectable || [], featureValues);
            }
        }

        if (["INCOME", "AUTO_INMARKET_VALUE", "AUTO_CURRENT_AGE", "INTEREST_BRAND"].includes(audienceFeature.feature.code)) {
            const filterValueIncome = filterValues.find((filterValue) => filterValue.code === audienceFeature.feature.code);
            featureValues = jsonDecodeArray(audienceFeature.featureValues);
            //if the min or max is not present in the featureValues, we have to add it from the selectable array
            if (!featureValues[0]?.min) {
                featureValues[0].min = filterValueIncome?.selectable[0].min;
            }
            if (!featureValues[0]?.max) {
                featureValues[0].max = filterValueIncome?.selectable[0].max;
            }
        }

        //interest have many selects we have to create the select or update the good one
        if (audienceFeature.feature.code === "INTEREST") {
            featureValues = filterValues.find((filterValue) => filterValue.code === audienceFeature.feature.code)?.selected ?? [];
            const featureIdx = featureValues.findIndex((feature) => feature.featureId === audienceFeature.id);
            if (featureIdx > -1) {
                featureValues[featureIdx].selected = jsonDecodeArray(audienceFeature.featureValues);
            } else {
                featureValues.push({
                    featureId: audienceFeature.id,
                    linkTo: Number(audienceFeature.linkTo),
                    selected: jsonDecodeArray(audienceFeature.featureValues),
                    notOperator: audienceFeature.notOperator
                });
            }
        }
        //get the feature (may be in subfeature)
        let featureCode = audienceFeature.subFeature ? audienceFeature.subFeature.code : audienceFeature.feature.code;
        //update the filterValues
        let filtersValuesIdx = filtersValues.findIndex((filterValue) => filterValue.code === featureCode);
        if (filtersValuesIdx > -1) {
            filtersValues[filtersValuesIdx].selected = featureValues;
        }
    });
    //remove filterValue if not in the audienceFeatures anymore
    filtersValues?.forEach((filterValue, idx) => {
        const audienceFeature = audienceFeatures?.find(
            (audienceFeature) => audienceFeature.feature.code === filterValue.code || audienceFeature.subFeature?.code === filterValue.code
        );
        //for the interest, we have to look at the selected array
        if (filterValue.code === "INTEREST") {
            filterValue.selected.forEach((selected, idx) => {
                const audienceFeature = audienceFeatures.find((audienceFeature) => audienceFeature.id === selected.featureId);
                if (!audienceFeature) {
                    filterValue.selected.splice(idx, 1);
                }
            });
        }
        if (!audienceFeature) {
            filtersValues[idx].selected = [];
        }
    });
    return filtersValues;
};

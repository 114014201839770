import {GridValueGetterParams} from '@mui/x-data-grid';
import {accountTypeName, EUserGrade} from 'application/utils/granter.utils';
import {TDataColumnModel} from '../../utils/dataColumnModel.utils';
import {ICompanyUsers} from '../dataTypes/companyUser';


function getFullName(params: any) {
    return `${params.getValue(params.id, 'firstName') || ''} ${
        params.getValue(params.id, 'lastName') || ''
    }`;
}

export const brandUsersColumns: TDataColumnModel = {
    firstName: {
        field: 'name',
        headerName: 'Name',
        sortable: false,
        minWidth: 120,
        flex: 1,
        align: 'left',
        headerAlign: 'left',
        type: 'avatar',
        valueGetter: (params: GridValueGetterParams<ICompanyUsers>) =>
            `${params.row.firstName || ''} ${
                params.row.lastName || ' '
            }`,
        sortComparator: (v1, v2, cellParams1, cellParams2) =>
            getFullName(cellParams1)
                .localeCompare(getFullName(cellParams2)),
    },
    email: {
        field: 'email',
        headerName: 'Email',
        minWidth: 90,
        flex: 1,
        type: 'string',
        valueGetter: (params: GridValueGetterParams<ICompanyUsers>) => params.row.email,
    },
    role: {
        field: 'role',
        headerName: 'Role',
        minWidth: 100,
        flex: 1,
        type: 'string',
        valueGetter: (params: GridValueGetterParams<ICompanyUsers>) => {
            return accountTypeName[params.row.role as EUserGrade]
        }
        ,
    },
    mfa: {
        field: 'isMfaSetupDone',
        headerName: 'MFA',
        width: 20,
        maxWidth: 70,
        type: 'string',
        valueGetter: (params: GridValueGetterParams<ICompanyUsers>) => {
            return params.row.isMfaSetupDone ? 'Active' : 'No Set'
        }

    },
};
import {useAuthActions, useAuthState} from "../../../../redux/slices/auth.slices";
import React, {useEffect} from "react";
import {ReactHookFormItem} from "../../../../application/entities/dataTypes/forms";
import {useTranslation} from "react-i18next";
import {useForm} from "react-hook-form";
import {getLogoLogin} from "../../../../application/utils/domain.utils";
import {AuthHeader, AuthSignZone, HtmlForm, InputWrapper} from "../authentication.styled";
import {H5, HeaderZoneIconBar, HeaderZoneMessage, HeaderZoneTitle} from "../../../ui/AppElements";
import {AppIcon} from "../../../ui/AppIcon";
import {InputLabel} from "../../../form/Input";
import {Button, CircularProgress, Fade, TextField} from "@mui/material";
import {pageUtils} from "../../../../application/pages/pages.utils";
import {PageId} from "../../../../application/pages/pages.config";
import {useNavigate} from "react-router";
import {authService} from "../../../../application/services/auth.service";
import {authStore} from "../../../../stores/auth.store";

export const MfaCode = () => {
    const {verifyMfaCode, doSignOut} = useAuthActions();
    const {cognitoUser} = useAuthState();
    const {t} = useTranslation();
    const {signOut} = authService
    const navigate = useNavigate();
    const AUTHStore = authStore();
    const [loadingState, setLoadingState] = React.useState<boolean>(false);
    const totpField: ReactHookFormItem = {
        name: 'totp',
        label: t('auth.mfa.totpLabel'),
        type: 'text',
        options: {
            minLength: {
                value: 6,
                message: t('auth.mfa.totpError')
            }
        }
    }

    const logoLogin = getLogoLogin()

    const {
        handleSubmit,
        control,
        register,
        getValues,
        formState: {isValid, errors},
    } = useForm({mode: 'all'});


    const onSubmit = (values: any) => {
        setLoadingState(true);
        verifyMfaCode({cognitoUser, code: values.totp}).then((verifRes) => {
            navigate('/')
            setLoadingState(false);
        }).finally(() => {
            setLoadingState(false)
        });
    }

    const onBack = () => {
        AUTHStore.loggedIn = false

        doSignOut().then(() => {
            navigate("/");

        });

    }


    useEffect(() => {
        if (!cognitoUser) {
            navigate(pageUtils.getPagePathById(PageId.signin))
        }
    }, [cognitoUser])

    return (
        <Fade in={true} timeout={1000} unmountOnExit>
            <AuthSignZone>
                <AuthHeader>
                    <HeaderZoneIconBar>
                        {(logoLogin) ?
                            <img src={logoLogin} alt="logo"
                                 style={{display: 'block', margin: 'auto', marginBottom: '1rem', maxHeight: '55px'}}/>
                            :
                            <AppIcon display="inline" icon="LoginIcon" fontSize="medium"/>
                        }
                    </HeaderZoneIconBar>
                    <HeaderZoneTitle>
                        <H5 display={'inline'}>{t('auth.mfa.title')}</H5>
                    </HeaderZoneTitle>
                    <HeaderZoneMessage>
                        <p>{t('auth.mfa.message')}</p>
                    </HeaderZoneMessage>
                </AuthHeader>
                <HtmlForm>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputWrapper key={'totp'}>
                            <InputLabel>{'Your TOTP code'}</InputLabel>
                            <TextField
                                {...register(totpField.name, totpField.options)}
                                type={totpField.type}
                                name={totpField.name}
                                error={errors[totpField.name]}
                                helperText={errors?.totpField?.message}
                                style={{width: 224}}
                            />
                        </InputWrapper>


                        <br/>
                        <Button
                            id={'cyLoginButton'}
                            data-cy="login-button"
                            variant="contained"
                            type={'submit'}
                            startIcon={loadingState ?
                                <CircularProgress style={{color: 'white'}} size={24}/> : undefined}
                            disabled={loadingState}
                        >
                            {t('auth.mfa.submitText')}
                        </Button>
                        <br/><br/>
                        <Button onClick={() => onBack()}>
                            Back to sign in
                        </Button>


                    </form>
                </HtmlForm>
            </AuthSignZone>
        </Fade>

    );
}
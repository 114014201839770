import {Button, CircularProgress, Fade, TextField} from '@mui/material';
import {useAuthActions, useAuthState} from 'redux/slices/auth.slices';
import {AppIcon} from 'components/ui/AppIcon';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import {AuthHeader, AuthSignZone, HtmlForm, InputWrapper,} from '../authentication.styled';
import {H5, HeaderZoneIconBar, HeaderZoneMessage, HeaderZoneTitle} from '../../../ui/AppElements';
import {InputLabel} from '../../../form/Input';
import {pageUtils} from '../../../../application/pages/pages.utils';
import {PageId} from '../../../../application/pages/pages.config';
import {RegisterOptions, useForm} from 'react-hook-form';
import {authStore} from 'stores/auth.store';
import {useUiStore} from 'redux/ui.store';
import {IAccounts} from 'types';
import {useLoggedUser} from 'components/hooks/useLoggedInUser';
import {getLogoLogin} from 'application/utils/domain.utils';
import {useNavigate} from "react-router";

interface Props {
}

interface ISigninState {
    email: string;
    password: string;

    [key: string]: any;
}

interface ReactHookFormItem {
    name: string;
    label: string;
    type: 'text' | 'password' | 'email' | 'number' | 'select' | 'checkbox' | 'radio' | 'date' | 'time' | 'url' | 'tel' | 'file' | 'range' | 'search' | 'hidden' | 'image' | 'submit' | 'reset' | 'button';
    placeholder?: string;
    options: RegisterOptions
}

const Signin: React.FC = (props: Props) => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {mfaSetupRequired} = useAuthState();

    const emailField: ReactHookFormItem = {
        name: 'email',
        label: t('auth.signIn.emailLabel'),
        type: 'text',
        options: {
            required: true,
            pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: t('auth.signIn.emailError')
            }
        }
    }

    const passwordField: ReactHookFormItem = {
        name: 'password',
        label: t('auth.signIn.passwordLabel'),
        type: 'password',
        options: {
            minLength: {
                value: 8,
                message: t('auth.signIn.passwordError')
            }
        }
    }

    const {
        handleSubmit,
        control,
        register,
        getValues,
        formState: {isValid, errors},
    } = useForm({mode: 'all'});


    const onSubmit = (values: any) => {
        setLoadingState(true);
        authActions.doSignin(values).then((signinRes) => {

            if (signinRes.payload.isMfaRequired) {
                AUTHStore.loggedIn = true
                AUTHStore.mfaSetupRequired = true
                navigate(pageUtils.getPagePathById(PageId.mfa_setup))
            }
            //new authStore non intrusive implementation
            if (signinRes?.payload?.cognitoUser) {

                AUTHStore.loggedIn = true
                AUTHStore.cognitoUser = signinRes.payload.cognitoUser;
            }

            setLoadingState(false);
        }).finally(() => {
            setLoadingState(false)
        });
    }

    const [loadingState, setLoadingState] = React.useState<boolean>(false);
    const authActions = useAuthActions();
    const authState = useAuthState();
    const AUTHStore = authStore();
    const loggedInUser = useLoggedUser()

    const logoLogin = getLogoLogin()

    React.useEffect(() => {
        // clear errors showing on enter
        authActions.resetError();
        loggedInUser.remove()
        useUiStore.setState({
            activeFrame: '',
            activeMenuButton: '',
            accountMode: undefined,
            currentBrand: {} as IAccounts,
            currentCompany: {} as IAccounts
        });
    }, []);

    React.useEffect(() => {

        if ([authState.challengeName, authState?.error?.code].includes('NEW_PASSWORD_REQUIRED')) {
            const url = pageUtils.getPagePathById(PageId.user_invitation, {email: getValues('email')});
            setTimeout(() => {
                document.location.href = url
                // history(url); CMVP-1440
            }, 4000);
        }
        if (authState?.mfaRequired) {
            navigate(pageUtils.getPagePathById(PageId.mfa_code));
        }
    }, [authState]);


    return (
        <Fade in={true} timeout={1000} unmountOnExit>
            <AuthSignZone>
                <AuthHeader>
                    <HeaderZoneIconBar>
                        {(logoLogin) ?
                            <img src={logoLogin} alt="logo"
                                 style={{display: 'block', margin: 'auto', marginBottom: '1rem', maxHeight: '55px'}}/>
                            :
                            <AppIcon display="inline" icon="LoginIcon" fontSize="medium"/>
                        }
                    </HeaderZoneIconBar>
                    <HeaderZoneTitle>
                        <H5 display={'inline'}>{t('auth.signIn.title')}</H5>
                    </HeaderZoneTitle>
                    <HeaderZoneMessage>
                        <p>{t('auth.signIn.message')}</p>
                    </HeaderZoneMessage>
                </AuthHeader>
                <HtmlForm>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <InputWrapper key={'email'}>
                            <InputLabel>{'Your email'}</InputLabel>
                            <TextField
                                {...register(emailField.name, emailField.options)}
                                type={emailField.type}
                                name={emailField.name}
                                error={errors[emailField.name]}
                                helperText={errors?.email?.message}
                                style={{width: 224}}
                            />
                        </InputWrapper>

                        <InputWrapper key={'password'}>
                            <InputLabel>Password</InputLabel>
                            <TextField
                                {...register(passwordField.name, passwordField.options)}
                                type={passwordField.type}
                                name={passwordField.name}
                                error={errors?.password}
                                helperText={errors?.password?.message}
                                style={{width: 224}}
                            />
                        </InputWrapper>

                        <br/>
                        <Button
                            id={'cyLoginButton'}
                            data-cy="login-button"
                            variant="contained"
                            type={'submit'}
                            startIcon={loadingState ?
                                <CircularProgress style={{color: 'white'}} size={24}/> : undefined}
                            disabled={loadingState}
                        >
                            {t('auth.signIn.submitText')}
                        </Button>
                        <br/>
                        <br/>
                        <Link to={pageUtils.getPagePathById(PageId.retrieve_password)}
                              data-cy={'forgotten-password-link'}>
                            {t('auth.signIn.forgottenPasswordLink')}
                        </Link>
                    </form>
                </HtmlForm>
            </AuthSignZone>
        </Fade>
    );
};

export {Signin};
